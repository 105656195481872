<script setup>
import { computed, ref } from 'vue'
import CoreButton from './CoreButton.vue'
import {
    acceptTransfer,
    changePrice,
    closeEscrow,
    endEscrowEarly,
    transferOut,
    setForSale,
} from '../dataProvider.js'
import { useWalletStore } from '../stores/wallet'
import { BigNumber, ethers } from 'ethers'
import CoreInput from './CoreInput.vue'
import AddressInput from './AddressInput.vue'
import { useRouter } from 'vue-router'
import toast from '../utils/toast.js'
import EscrowAccount from '../../abis/EscrowAccount.json'
import { formatEther } from '../utils/numbers'
import { useWeb3ModalAccount } from '@web3modal/ethers5/vue'
import { ARBITRUM, AVALANCHE } from '../addresses'
import AlertWarning from './AlertWarning.vue'

const emit = defineEmits(['close', 'updated'])

const { address, chainId } = useWeb3ModalAccount()
const walletStore = useWalletStore()
const router = useRouter()

walletStore.updateEscrowAccount()

const txState = ref(null)
const txStateMessage = computed(() => {
    switch (txState.value) {
        case 'transfer_loading':
            return 'Transferring account to your wallet...'
        case 'transfer_complete':
            return 'Account transfer complete'
        case 'transfer_accept_loading':
            return 'Accepting account transfer...'
        case 'transfer_accept_complete':
            return 'Account transfer accepted'
        case 'escrow_close_loading':
            return 'Closing account...'
        case 'escrow_closed':
            return 'Account closed'
        case 'escrow_ended':
            return 'Account ended'
        case 'price_change_loading':
            return 'Changing price...'
        case 'price_changed':
            return 'Price changed'
        case 'set_for_sale_loading':
            return 'Relisting account for sale...'
        case 'set_for_sale_complete':
            return 'Account has been relisted'
        case 'error':
            return 'An error has occurred'
        default:
            return txState.value
    }
})

function completeTransfer() {
    txState.value = 'transfer_accept_loading'

    return acceptTransfer(walletStore.escrowAddress)
        .then((res) => {
            return res.wait().then(() => {
                txState.value = 'transfer_accept_complete'
                toast.success('Success. Account transfer complete')
                return walletStore.updateEscrowAccount()
            })
        })
        .catch((err) => {
            const error = err.error
            const message = error.data.message || error.message
            toast.error(message)
        })
}

const transferOutAddress = ref('')

function transferOutTo() {
    txState.value = 'transfer_loading'

    return transferOut(
        walletStore.escrowAddress,
        transferOutAddress.value || address.value
    )
        .then((res) => {
            return res.wait().then(() => {
                txState.value = 'transfer_complete'
                return walletStore.updateEscrowAccount()
            })
        })
        .catch((err) => {
            const error = err.error
            const message = error.data.message || error.message
            toast.error(message)
        })
        .finally(() => {
            txState.value = null
        })
}

function closeAccount() {
    txState.value = 'escrow_close_loading'

    return closeEscrow(walletStore.escrowAddress)
        .then((res) => {
            res.wait().then(() => {
                txState.value = 'escrow_closed'

                walletStore.escrowAddress = null
                walletStore.escrowAccount = null
                walletStore.pendingReceivers = null
                walletStore.allowance = BigNumber.from(0)

                emit('close')

                if ('gtag' in window) {
                    window.gtag('event', 'manage_listing_close', {
                        event_category: 'manage_listing',
                    })
                }
            })
        })
        .catch((err) => {
            const error = err.error
            const message = error.data.message || error.message
            txState.value = null
            toast.error(message)
        })
}

const newPrice = ref(null)
async function changePriceLocal() {
    txState.value = 'price_change_loading'
    if (!newPrice.value) return

    const bigNumberPrice = ethers.utils.parseUnits(
        newPrice.value.toString(),
        'ether'
    )

    const promise = await changePrice(walletStore.escrowAddress, bigNumberPrice)

    return promise.wait().then(() => {
        toast.success('Price changed')
        emit('close')

        if ('gtag' in window) {
            window.gtag('event', 'manage_listing_change_price', {
                event_category: 'manage_listing',
            })
        }
    })
}

function endEarly() {
    return endEscrowEarly(walletStore.escrowAddress).then((res) => {
        return res
            .wait()
            .then(async () => {
                const walletResponse = await walletStore.updateEscrowAccount()

                toast.success('Listing ended')

                if ('gtag' in window) {
                    window.gtag('event', 'manage_listing_end_early', {
                        event_category: 'manage_listing',
                    })
                }

                return walletResponse
            })
            .catch((err) => {
                const error = err.error
                const message = error.data.message
                toast.error(message)
            })
    })
}

const salePrice = ref(null)
async function relistAccount() {
    txState.value = 'set_for_sale_loading'

    if (!salePrice.value) {
        txState.value = 'error'

        setTimeout(() => {
            txState.value = null
        }, 3000)

        return
    }

    const salePriceBigNumber = ethers.utils.parseUnits(
        salePrice.value.toString(),
        'ether'
    )

    const promise = await setForSale(
        walletStore.escrowAddress,
        salePriceBigNumber
    ).then((res) => {
        return res.wait()
    })

    await walletStore.updateEscrowAccount()

    txState.value = 'set_for_sale_complete'

    emit('close')
    await router.push({ name: 'home' })

    if ('gtag' in window) {
        window.gtag('event', 'manage_listing_relist', {
            event_category: 'manage_listing',
        })
    }

    return promise
}

function compoundAndClaim() {
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner()

    const EscrowAccountContract = new ethers.Contract(
        walletStore.escrowAddress,
        EscrowAccount.abi,
        signer
    )

    return EscrowAccountContract.CompoundAndClaim()
        .then((res) => {
            return res.wait().then((res) => {
                toast.success('Compound and claim successful')

                if ('gtag' in window) {
                    window.gtag('event', 'manage_listing_compound', {
                        event_category: 'manage_listing',
                    })
                }
            })
        })
        .catch((err) => {
            const error = err.error
            const message = error.data.message || error.message
            toast.error(message)
        })
}
</script>

<template>
    <div>
        <p
            v-if="
                walletStore.getEscrowAccountState ===
                    'purchased_pending_other' || txState === 'transfer_complete'
            "
        >
            Go to the
            <a
                :href="`https://app.gmx.io/#/complete_account_transfer/${
                    walletStore.escrowAddress
                }/${transferOutAddress || walletStore.pendingReceivers}`"
                target="_blank"
                class="font-semibold text-blue-500"
            >
                GMX official website
            </a>
            to complete the transfer
        </p>

        <div class="space-y-4">
            <div
                v-if="walletStore.getEscrowAccountState === 'listed'"
                class="flex gap-2"
            >
                <CoreInput
                    v-model.number="newPrice"
                    type="number"
                    step="0.01"
                    min="0.001"
                    max="10000000"
                    suffix="GMX"
                    :placeholder="
                        formatEther(walletStore.escrowAccount.sale_price, 2)
                    "
                />
                <CoreButton
                    color="blue"
                    class="w-full"
                    :handler="changePriceLocal"
                >
                    Change price
                </CoreButton>
            </div>

            <CoreButton
                v-if="
                    walletStore.getEscrowAccountState === 'purchased' ||
                    walletStore.getEscrowAccountState === 'listed' ||
                    walletStore.getEscrowAccountState === 'listed_ended' ||
                    walletStore.getEscrowAccountState ===
                        'purchased_pending_empty'
                "
                :handler="compoundAndClaim"
                class="w-full"
            >
                Compound and claim
            </CoreButton>

            <template
                v-if="
                    walletStore.getEscrowAccountState === 'created' ||
                    walletStore.getEscrowAccountState === 'listed_sold' ||
                    walletStore.getEscrowAccountState === 'listed_ended' ||
                    walletStore.getEscrowAccountState ===
                        'purchased_transferred' ||
                    walletStore.getEscrowAccountState ===
                        'purchased_pending_empty'
                "
            >
                <AlertWarning class="my-3">
                    You have
                    <template v-if="chainId === ARBITRUM">
                        {{ formatEther(walletStore.escrowAccount.pending_ETH) }}
                        ETH
                    </template>

                    <template v-if="chainId === AVALANCHE">
                        {{ formatEther(walletStore.escrowAccount.pending_ETH) }}
                        AVAX
                    </template>

                    pending. Compound and claim before you close the escrow.
                </AlertWarning>
            </template>

            <CoreButton
                v-if="walletStore.getEscrowAccountState === 'listed'"
                color="red"
                class="w-full"
                :handler="endEarly"
            >
                End early
            </CoreButton>

            <CoreButton
                v-if="walletStore.getEscrowAccountState === 'purchased'"
                color="blue"
                class="w-full"
                :handler="transferOutTo"
            >
                Transfer out to GMX
            </CoreButton>

            <div
                v-if="
                    walletStore.getEscrowAccountState === 'purchased' ||
                    walletStore.getEscrowAccountState === 'listed_ended' ||
                    walletStore.getEscrowAccountState === 'listed_expired'
                "
            >
                <div class="my-3">
                    <label for="salePrice">Sale price</label>
                    <CoreInput
                        id="salePrice"
                        v-model="salePrice"
                        type="number"
                        suffix="GMX"
                        placeholder="45.00"
                        class="w-full"
                    />
                </div>

                <CoreButton
                    color="blue"
                    class="w-full"
                    :handler="relistAccount"
                >
                    Relist
                </CoreButton>
            </div>

            <div
                v-if="
                    walletStore.getEscrowAccountState === 'listed_ended' ||
                    walletStore.getEscrowAccountState === 'listed_expired' ||
                    walletStore.getEscrowAccountState === 'purchased'
                "
            >
                <div class="my-3">
                    <label for="transferOut">Transfer</label>
                    <AddressInput
                        id="transferOut"
                        v-slot="{ isAddress }"
                        v-model="transferOutAddress"
                        placeholder="Address to transfer escrow to"
                        class="w-full"
                    >
                        <CoreButton
                            :disabled="!isAddress"
                            color="blue"
                            class="w-full"
                            :handler="transferOutTo"
                        >
                            <template v-if="isAddress">Transfer</template>
                            <template v-else>Enter a valid address</template>
                        </CoreButton>
                    </AddressInput>
                </div>
            </div>

            <CoreButton
                v-if="walletStore.getEscrowAccountState === 'purchased_pending'"
                color="blue"
                class="w-full"
                :handler="completeTransfer"
            >
                Complete transfer
            </CoreButton>

            <CoreButton
                v-if="
                    walletStore.getEscrowAccountState === 'created' ||
                    walletStore.getEscrowAccountState === 'listed_sold' ||
                    walletStore.getEscrowAccountState ===
                        'purchased_transferred' ||
                    walletStore.getEscrowAccountState ===
                        'purchased_pending_empty'
                "
                color="red"
                class="w-full"
                :handler="closeAccount"
            >
                Close escrow account
            </CoreButton>
        </div>

        <div v-if="txStateMessage" class="mt-2 text-center text-sm italic">
            {{ txStateMessage }}...
        </div>
    </div>
</template>
