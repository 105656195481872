<script setup>
import { computed, ref, watch } from 'vue'
import { CheckCircleIcon } from '@heroicons/vue/24/outline'
import CoreButton from './CoreButton.vue'
import {
    acceptTransfer,
    addMarginToPrice,
    approve,
    checkAllowance,
    getQuote,
    purchaseListingOnArbitrum,
    purchaseListingOnAvalanche,
} from '../dataProvider.js'
import { useWalletStore } from '../stores/wallet'
import { formatEther, formatUsd, formatUSDC } from '../utils/numbers.js'
import { useRouter } from 'vue-router'
import toast from '../utils/toast.js'
import CoreSelect from './CoreSelect.vue'
import CoreInput from './CoreInput.vue'
import AlertWarning from './AlertWarning.vue'
import { BigNumber } from 'ethers'
import { useWeb3ModalAccount } from '@web3modal/ethers5/vue'
import { ARBITRUM, AVALANCHE } from '../addresses'

const emit = defineEmits(['close', 'updated'])

const props = defineProps({
    account: {
        type: Object,
        required: true,
    },
    sellersAddress: {
        type: String,
        required: true,
    },
})

const { address, chainId, isConnected } = useWeb3ModalAccount()
const walletStore = useWalletStore()
const router = useRouter()
const txState = ref(null)
const shouldTransferToGMX = ref(true)
const tokenOptions = ref([
    {
        label: 'GMX',
        value: 'GMX',
    },
    {
        label: 'USDC',
        value: 'USDC',
    },
])
const quoteIsLoading = ref(false)
const tokenPrice = ref(BigNumber.from(0))
const tokenPool = ref(null)
const binstep1 = ref(null)
const binstep2 = ref(null)
const version1 = ref(null)
const version2 = ref(null)
const allowanceGMX = ref(BigNumber.from(0))
const allowanceUSDC = ref(BigNumber.from(0))

if (chainId.value === ARBITRUM) {
    tokenOptions.value.push({
        label: 'ETH',
        value: 'ETH',
    })
}

if (chainId.value === AVALANCHE) {
    tokenOptions.value.push({
        label: 'AVAX',
        value: 'AVAX',
    })
}

const buyWithToken = ref(tokenOptions.value[1])

const updateAllowance = () => {
    return checkAllowance(
        address.value,
        props.sellersAddress,
        buyWithToken.value.value
    )
        .then((res) => {
            if (buyWithToken.value.value === 'GMX') {
                allowanceGMX.value = res
            }

            if (buyWithToken.value.value === 'USDC') {
                allowanceUSDC.value = res
            }
        })
        .catch(() => {
            txState.value = null
        })
}

updateAllowance()
walletStore.updateEscrowAccount()

if (walletStore.getEscrowAccountState === 'purchased_pending') {
    txState.value = 'purchase_complete'
}

if (walletStore.getEscrowAccountState === 'purchased_pending_empty') {
    txState.value = 'transfer_complete'
}

/**
 * Logic for paying in other tokens
 *
 */
watch(
    buyWithToken,
    (val) => {
        updateQuote(val.value)
        updateAllowance()
    },
    { immediate: true }
)

function updateQuote(token) {
    if (token === 'GMX') {
        tokenPrice.value = props.account.sale_price
        tokenPool.value = null
        return
    }

    quoteIsLoading.value = true
    getQuote(props.account.sale_price, token).then(async (res) => {
        tokenPrice.value = res.price
        tokenPool.value = res.pool
        binstep1.value = res.binstep1
        binstep2.value = res.binstep2
        version1.value = res.version1
        version2.value = res.version2

        quoteIsLoading.value = false
    })
}

const needsApproval = computed(() => {
    if (buyWithToken.value.value === 'USDC') {
        return allowanceUSDC.value.lt(tokenPrice.value)
    }

    if (buyWithToken.value.value === 'GMX') {
        return allowanceGMX.value.lt(tokenPrice.value)
    }

    // ETH
    return false
})

async function onApprove() {
    txState.value = 'approving'

    let price = tokenPrice.value

    if (buyWithToken.value.value === 'USDC') {
        price = addMarginToPrice(tokenPrice.value)
    }

    return approve(props.sellersAddress, price, buyWithToken.value.value)
        .then((res) => {
            return res.wait().then(() => {
                updateAllowance()
            })
        })
        .catch((err) => {
            const error = err.error
            const message = error.data.message || error.message
            toast.error(message)
        })
        .finally(() => {
            txState.value = null
        })
}

function completeTransfer() {
    return acceptTransfer(walletStore.escrowAddress)
        .then((res) => {
            return res.wait().then(() => {
                txState.value = 'transfer_complete'
            })
        })
        .catch((err) => {
            const error = err.error
            const message = error.data.message || error.message
            toast.error(message)
        })
}

function onPurchase() {
    const sellersAddress = props.sellersAddress
    const buyersAddress = address.value
    txState.value = 'purchasing'

    // if (
    //     !walletStore.allowance.isZero() &&
    //     props.account.sale_price.gt(walletStore.allowance)
    // ) {
    //     alert('Sale price has been increased')
    //     return
    // }

    let onfulfilled = async (res) => {
        return res.wait().then(async () => {
            return walletStore.updateEscrowAccount().then(async () => {
                if ('gtag' in window) {
                    window.gtag('event', 'purchase', {
                        transaction_id: props.sellersAddress,
                        value: formatUsd(
                            props.account.sale_price,
                            walletStore.price.GMX_USD
                        ),
                        currency: 'USD',
                    })
                }

                if (shouldTransferToGMX.value === true) {
                    txState.value = 'purchase_complete'
                }

                if (shouldTransferToGMX.value === false) {
                    emit('close')

                    toast.success('The account was successfully purchased')

                    await router.push({
                        name: 'listing.show',
                        params: { address: walletStore.escrowAddress },
                    })
                }
            })
        })
    }

    let onrejected = (err) => {
        console.error(err)
        console.log(err.message)
        txState.value = null
        const error = err.error
        const message = error.data.message || error.message
        toast.error(message)
    }

    if (chainId.value === AVALANCHE) {
        return purchaseListingOnAvalanche(
            sellersAddress,
            shouldTransferToGMX.value,
            buyWithToken.value.value,
            binstep1.value,
            binstep2.value,
            version1.value,
            version2.value,
            tokenPrice.value
        )
            .then(onfulfilled)
            .catch(onrejected)
    }

    if (chainId.value === ARBITRUM) {
        return purchaseListingOnArbitrum(
            sellersAddress,
            buyersAddress,
            props.account.sale_price,
            shouldTransferToGMX.value,
            buyWithToken.value.value,
            tokenPool.value,
            tokenPrice.value
        )
            .then(onfulfilled)
            .catch(onrejected)
    }
}
</script>

<template>
    <div
        v-if="txState === 'purchase_complete'"
        class="flex flex-col items-center justify-center"
    >
        <div class="mt-3 font-semibold">Purchase successful</div>

        <div>
            You have purchased the account and it's now in your escrow address.
            Please accept transfer to send to your personal address.
        </div>

        <div class="mt-5 flex w-full flex-col gap-3">
            <CoreButton
                v-if="txState !== 'transfer_complete'"
                class="w-full hover:bg-blue-800"
                :handler="completeTransfer"
            >
                Accept transfer
            </CoreButton>
        </div>
    </div>

    <div
        v-else-if="txState === 'transfer_complete'"
        class="flex flex-col items-center justify-center"
    >
        <CheckCircleIcon class="h-20 w-20 text-green-600" />
        <div class="mt-3 font-semibold">Success</div>

        <div>Account has been transferred to your wallet</div>

        <a
            href="https://app.gmx.io/#/earn"
            target="_blank"
            rel="noopener noreferrer"
            class="mt-3 text-blue-700 hover:underline"
        >
            View your account on gmx.io
        </a>

        <div class="mt-5 flex w-full flex-col gap-3">
            <CoreButton
                v-if="txState !== 'transfer_complete'"
                class="w-full hover:bg-blue-800"
                :handler="completeTransfer"
            >
                Accept transfer
            </CoreButton>

            <CoreButton class="w-full" @click="$emit('close')">
                Close
            </CoreButton>
        </div>
    </div>

    <p
        v-else-if="
            walletStore.getEscrowAccountState === 'purchased_pending_empty'
        "
        class="mt-2"
    >
        You have already made a purchase with the connected account. Please
        create a new account to purchase this listing.
    </p>

    <div v-else class="flex flex-col gap-4">
        <label class="inline-block">
            <span>Select token to buy with</span>
            <CoreSelect
                v-model="buyWithToken"
                :options="tokenOptions"
                type="checkbox"
            />
        </label>

        <label class="inline-block">
            <span>Sale price</span>
            <CoreInput
                class="w-full"
                type="text"
                readonly
                suffix="GMX"
                :value="formatEther(props.account.sale_price)"
            />
        </label>

        <label
            v-if="buyWithToken?.value === 'USDC' && tokenPrice"
            class="inline-block"
        >
            <span>Price</span>
            <CoreInput
                class="w-full"
                type="text"
                readonly
                suffix="USDC"
                :value="formatUSDC(tokenPrice, 2)"
            />
        </label>

        <label
            v-if="buyWithToken?.value === 'AVAX' && tokenPrice"
            class="inline-block"
        >
            <span>Price</span>
            <CoreInput
                class="w-full"
                type="text"
                readonly
                suffix="AVAX"
                :value="formatEther(tokenPrice, 4)"
            />
        </label>

        <label
            v-if="buyWithToken?.value === 'ETH' && tokenPrice"
            class="inline-block"
        >
            <span>Price</span>
            <CoreInput
                class="w-full"
                type="text"
                readonly
                suffix="ETH"
                :value="formatEther(tokenPrice, 4)"
            />
        </label>

        <div>
            <label class="flex items-center">
                <input v-model="shouldTransferToGMX" type="checkbox" />
                <span class="ml-1">Transfer account to GMX</span>
            </label>
            <div class="text-xs text-zinc-400">
                This will automatically start a transfer out to your GMX wallet.
                Uncheck if you want to relist, or transfer to a different
                address.
            </div>
        </div>

        <AlertWarning
            v-if="walletStore.getEscrowAccountState === 'listed_sold'"
        >
            Can't purchase accounts with an active escrow account. Go to your
            account and close it.
        </AlertWarning>

        <AlertWarning v-if="walletStore.getEscrowAccountState === 'listed'">
            Can't purchase accounts when you have an active listing.
        </AlertWarning>

        <CoreButton
            v-else-if="needsApproval"
            :loading="!!txState"
            class="w-full"
            :handler="onApprove"
        >
            Approve
        </CoreButton>
        <div v-else-if="walletStore.getEscrowAccountState === null">
            <CoreButton
                :loading="quoteIsLoading"
                class="w-full hover:bg-blue-800"
                :handler="onPurchase"
            >
                Buy for
                <template v-if="buyWithToken.value === 'ETH'">
                    {{ formatEther(tokenPrice, 6) }}
                    ETH
                </template>
                <template v-else-if="buyWithToken.value === 'AVAX'">
                    {{ formatEther(tokenPrice, 6) }}
                    AVAX
                </template>
                <template v-else-if="buyWithToken.value === 'USDC'">
                    {{ formatUSDC(tokenPrice) }}
                    USDC
                </template>
                <template v-else>
                    {{ formatEther(account.sale_price, 2) }} GMX
                </template>
            </CoreButton>
        </div>
        <CoreButton
            v-if="walletStore.getEscrowAccountState === 'purchased_pending'"
            :loading="!!txState"
            class="w-full hover:bg-blue-800"
            :handler="completeTransfer"
        >
            Accept transfer
        </CoreButton>
    </div>
</template>
