<template>
    <CoreInput
        :model-value="modelValue"
        v-bind="$attrs"
        @update:model-value="onUpdate"
    />

    <div class="mt-3">
        <slot :is-address="isAddress"></slot>
    </div>
</template>

<script setup>
import CoreInput from './CoreInput.vue'
import { ethers } from 'ethers'
import { computed } from 'vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
    modelValue: {
        type: [String, Number],
        required: false,
        default: '',
    },
})

const isAddress = computed(() => {
    return ethers.utils.isAddress(props.modelValue)
})

function onUpdate($event) {
    // const isAddress = ethers.utils.isAddress($event)

    // if (isAddress) {
    emit('update:modelValue', $event)
    // }
}
</script>
