import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes.js'

const router = createRouter({
    history: createWebHistory(),
    routes,
    // Scroll to top
    scrollBehavior() {
        return { top: 0 }
    },
})

router.beforeResolve(async (routeTo) => {
    setTitle(routeTo.meta?.title)
})

function setTitle(str) {
    const titleEl = document.querySelector('title')

    let title = 'SNTL – esGMX Market'

    if (str) {
        title = `${str} | ${title}`
    }

    titleEl.innerHTML = title
}

export default router
