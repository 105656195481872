<script setup>
import { computed, ref } from 'vue'
import LoadingSpinner from './LoadingSpinner.vue'

const props = defineProps({
    icon: {
        type: Function,
        required: false,
        default: null,
    },
    size: {
        type: String,
        required: false,
        default: 'md',
    },
    color: {
        type: String,
        required: false,
        default: 'blue',
    },
    handler: {
        type: Function,
        required: false,
        default: null,
    },
    loading: Boolean,
    disabled: Boolean,
})

const loadingLocal = ref(false)
const loadingComputed = computed(() => {
    return props.loading || loadingLocal.value
})

const classes = computed(() => {
    const size = {
        xs: 'text-xs h-7 py-1 px-3',
        sm: 'text-sm h-8 py-2 px-4',
        md: 'text-sm h-10 py-2 px-4',
        lg: 'text-lg h-12 py-2 px-4',
    }[props.size]

    const color = {
        red: 'text-slate-200 bg-red-600 hover:bg-red-500 active:bg-red-400',
        blue: 'text-slate-200 bg-blue-700 hover:bg-blue-600 active:bg-blue-500',
        gray: 'text-slate-100 bg-zinc-800 hover:bg-zinc-700 active:bg-zinc-600',
        'light-gray':
            'text-slate-100 bg-zinc-700 hover:bg-zinc-600 active:bg-zinc-500',
    }[props.color]

    return [size, color]
})

function onHandlerClick() {
    if (typeof props.handler === 'function') {
        loadingLocal.value = true

        return props
            .handler()
            .catch(() => {
                // TODO: Add error handling
            })
            .finally(() => {
                loadingLocal.value = false
            })
    }
}
</script>

<template>
    <button
        class="inline-flex items-center justify-center whitespace-nowrap rounded-md transition disabled:cursor-not-allowed disabled:opacity-50"
        :class="classes"
        :disabled="disabled || loadingComputed"
        @click="onHandlerClick"
    >
        <LoadingSpinner v-if="loadingComputed" class="h-4 w-4" />

        <template v-if="!loadingComputed">
            <component
                :is="icon"
                v-if="icon"
                class="-ml-1 mr-1 inline-block h-5 w-5"
            />

            <slot />
        </template>
    </button>
</template>
