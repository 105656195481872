<script setup>
import { computed, ref, watch } from 'vue'
import { BigNumber } from 'ethers'
import { useWalletStore } from '../../../stores/wallet.js'
import { getAccountBalances, getAccountDetails } from '../../../readContracts'

import ApproveAmount from './ApproveAmount.vue'
import TransferAccount from './TransferAccount.vue'
import SetForSale from './SetForSale.vue'
import CloseEscrowAccount from './CloseEscrowAccount.vue'
import LoadingSpinner from '../../../components/LoadingSpinner.vue'
import AlertWarning from '../../../components/AlertWarning.vue'
import AccountDetails from '../../../components/AccountDetails.vue'
import { useWeb3ModalAccount } from '@web3modal/ethers5/vue'
import PriceEstimatorComponent from '../../../components/PriceEstimatorComponent.vue'
import CoreCard from '../../../components/CoreCard.vue'
import ReadMoreComponent from '../../../components/ReadMoreComponent.vue'
import { useRouter } from 'vue-router'

/**
 * TODO: Improve logic for loading state
 */

const walletStore = useWalletStore()
const { address, isConnected } = useWeb3ModalAccount()

const balances = ref({
    sbfGMX: BigNumber.from(0),
    vGMX: BigNumber.from(0),
    vGLP: BigNumber.from(0),
})

const hasVestedTokens = computed(() => {
    return balances.value.vGMX.gt(0) || balances.value.vGLP.gt(0)
})

const walletAccount = ref(null)

const getWalletAccount = (address) => {
    if (!address) {
        walletAccount.value = null
        return
    }

    getAccountBalances(address).then((res) => {
        balances.value = res
    })

    getAccountDetails(address).then((res) => {
        walletAccount.value = res
    })
}

watch(
    address,
    (val) => {
        getWalletAccount(val)
    },
    { immediate: true }
)

walletStore.updateEscrowAccount()
</script>

<template>
    <!-- Active listing warning -->
    <div
        v-if="walletStore.getEscrowAccountState === 'listed'"
        class="p-10 text-center"
    >
        <h1 class="mb-2 text-2xl font-bold">Already listed</h1>
        You already have an active listing. View it
        <router-link
            :to="{
                name: 'listing.show',
                params: { address: walletStore.escrowAddress },
            }"
            class="text-blue-60 underline"
        >
            here
        </router-link>
    </div>

    <template v-else>
        <!-- Account details -->
        <div
            v-if="
                isConnected &&
                !walletStore.isLoadingEscrow &&
                walletStore.getEscrowAccountState !== 'listed'
            "
            class="mx-auto my-8 flex max-w-screen-md"
        >
            <CoreCard title="Add new listing">
                <template #sub-title>
                    <a
                        :href="`${walletStore.explorerUrl}/address/${address}`"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="text-sm text-zinc-400 transition hover:text-zinc-300"
                    >
                        {{ address }}
                    </a>
                </template>

                <AccountDetails v-if="walletAccount" :account="walletAccount" />
            </CoreCard>
        </div>

        <div class="mx-auto my-8 max-w-md">
            <!-- Wallet not connected warning -->
            <div v-if="!isConnected" class="p-10 text-center">
                {{ address }}
                <h1 class="mb-2 text-2xl font-bold">Wallet not connected</h1>
                You need to connect your wallet to add listing
            </div>

            <!-- Active listing warning -->
            <div
                v-if="walletStore.getEscrowAccountState === 'listed_sold'"
                class="p-10 text-center"
            >
                <h1 class="mb-2 text-2xl font-bold">Already listed</h1>
                You already have an active listing that has been sold.
            </div>

            <!-- Vested tokens warning -->
            <AlertWarning v-if="hasVestedTokens">
                Please withdraw your vested esGMX and GLP tokens before listing
            </AlertWarning>

            <!-- Loading state -->
            <!--        <div-->
            <!--            v-if="walletStore.isLoadingEscrow"-->
            <!--            class="flex items-center justify-center p-10"-->
            <!--        >-->
            <!--            <LoadingSpinner class="mr-3 h-5 w-5" />-->
            <!--            Loading account...-->
            <!--        </div>-->

            <!-- Main listing steps -->
            <div class="pb-10">
                <CloseEscrowAccount
                    v-if="
                        walletStore.getEscrowAccountState ===
                        'purchased_pending_empty'
                    "
                />

                <!-- Step 1 – GMX approval -->
                <ApproveAmount
                    v-if="
                        walletStore.allowance.isZero() ||
                        walletStore.transferApproval.allowance.lt(
                            walletStore.transferApproval.approvalBalance
                        )
                    "
                />

                <!-- Step 2 – Transfer to escrow account -->
                <TransferAccount
                    :class="{
                        'pointer-events-none opacity-50':
                            walletStore.isTransferred ||
                            walletStore.allowance.isZero() ||
                            walletStore.transferApproval.allowance.lt(
                                walletStore.transferApproval.approvalBalance
                            ) ||
                            walletStore.getEscrowAccountState ===
                                'purchased_pending_empty',
                    }"
                />

                <!-- Step 3 – Create listing -->
                <SetForSale
                    :class="{
                        'pointer-events-none opacity-50': !(
                            walletStore.isTransferred &&
                            walletStore.allowance.gt(0)
                        ),
                    }"
                />

                <!-- Helper message -->
                <div class="my-4 text-xs text-zinc-400">
                    Once you create your listing it will be posted automatically
                    to our
                    <a
                        href="https://t.me/sntlmarkets"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="text-zinc-300 hover:underline"
                    >
                        telegram group
                    </a>
                    for feedback from buyers. You will be able to alter your
                    price easily after you deploy, if needed.
                </div>
            </div>

            <!-- Price estimator -->
            <CoreCard
                v-if="walletAccount"
                title="Price estimator"
                class="col-span-4"
            >
                <PriceEstimatorComponent :listing="walletAccount" />

                <ReadMoreComponent class="mt-10 space-y-4 leading-loose">
                    <p>
                        Default multipliers are set to what the community has
                        historically priced GMX accounts at, and may not reflect
                        current market conditions. Accounts are non-fungible, so
                        many factors go into pricing them. Multipliers in this
                        calculator can be changed to represent alternative
                        pricing structures.
                    </p>

                    <p>Factors that may impact price typically include:</p>

                    <ul class="list-disc pl-4">
                        <li>Size of account</li>
                        <li>Vesting requirements</li>
                        <li>
                            Presence or lack thereof of staked GMX on the
                            account
                        </li>
                        <li>Price of GMX -Market sentiment</li>
                    </ul>

                    <p>
                        It is recommended to list your account at a high price
                        and allow for prospective buyers to either place offers
                        directly on the SNTL DApp, and/or link up with them in
                        our
                        <a
                            class="underline"
                            href="https://t.me/sntlmarkets"
                            target="_blank"
                        >
                            Telegram
                        </a>
                        to negotiate a price.
                    </p>
                </ReadMoreComponent>
            </CoreCard>
        </div>
    </template>
</template>
