<script setup>
import { WalletIcon } from '@heroicons/vue/24/outline'
import { useWeb3Modal } from '@web3modal/ethers5/vue'
import { useWalletStore } from '../stores/wallet'
import CoreButton from './CoreButton.vue'

const modal = useWeb3Modal()
const walletStore = useWalletStore()
</script>

<template>
    <CoreButton
        v-if="!walletStore.address"
        class="h-9"
        size="sm"
        @click="modal.open"
    >
        Connect wallet
    </CoreButton>
    <button
        v-else
        class="inline-flex h-9 items-center rounded-md border border-zinc-700 px-3 py-1 text-sm text-white transition hover:border-zinc-100"
        @click="modal.open({ view: 'Account' })"
    >
        <WalletIcon class="hidden h-5 w-5 sm:mr-2 sm:block"></WalletIcon>

        <span>
            {{ walletStore.truncatedAddress }}
        </span>
    </button>
</template>
