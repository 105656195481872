<template>
    <div class="mx-auto max-w-3xl text-zinc-300">
        <h1 class="mb-12 text-3xl font-bold text-zinc-100">
            Frequently asked questions
        </h1>

        <div class="my-8 md:my-12">
            <h2 class="mb-2 text-xl font-semibold text-zinc-100">
                What is esGMX?
            </h2>

            <p>
                esGMX is the reward token of GMX. It is non transferable so to
                buy/ sell you will have to trade entire GMX accounts. If you
                click “view” on our listings table you will see all details on
                an account including how much GMX, esGMX, GLP and the tokens
                needed to vest rewards
            </p>
        </div>

        <div class="my-8 md:my-12">
            <h2 class="mb-2 text-xl font-semibold text-zinc-100">
                What is vesting?
            </h2>
            <p>
                esGMX can convert to GMX over 1 year, but you will need to
                obtain the amount the account held to gain those rewards. This
                is the “tokens needed to vest” in account details view
            </p>
        </div>

        <div class="my-8 md:my-12">
            <h2 class="mb-2 text-xl font-semibold text-zinc-100">Fees</h2>
            <p class="my-2">There is a fee taken from the seller side only.</p>
            <p class="my-2">
                Fee is dynamic based on the assets in your account.
            </p>

            <table class="mt-8 table">
                <thead>
                    <tr>
                        <th class="bg-zinc-900 p-2 px-4 text-left">Asset</th>
                        <th class="bg-zinc-900 p-2 px-4 text-left">Fee</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td class="px-4 py-1 text-left">esGMX</td>
                        <td class="px-4 py-1 text-left">2.5%</td>
                    </tr>

                    <tr>
                        <td class="px-4 py-1 text-left">Staked GMX</td>
                        <td class="px-4 py-1 text-left">1%</td>
                    </tr>

                    <tr>
                        <td class="px-4 py-1 text-left">GLP</td>
                        <td class="px-4 py-1 text-left">2.5%</td>
                    </tr>

                    <tr>
                        <td class="px-4 py-1 text-left">Multiplier points</td>
                        <td class="px-4 py-1 text-left">2.5%</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script setup></script>
