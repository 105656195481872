<template>
    <div>
        <div
            v-if="!showFullContent"
            v-bind="$attrs"
            class="text-fade h-32 overflow-hidden"
        >
            <div>
                <slot />
            </div>
        </div>

        <div v-if="showFullContent" v-bind="$attrs">
            <slot />
        </div>

        <button
            v-if="!showFullContent"
            class="rounded-lg border border-zinc-300 px-3 py-1 text-sm text-zinc-300 transition hover:border-zinc-100 hover:text-zinc-100"
            @click="showFullContent = true"
        >
            Show more
        </button>
    </div>
</template>

<script>
import { ref } from 'vue'

export default {
    setup() {
        const showFullContent = ref(false)

        return {
            showFullContent,
        }
    },
}
</script>

<style scoped>
.text-fade {
    mask-image: linear-gradient(
        to bottom,
        #000 0px,
        transparent 120px,
        transparent 0px
    );
}

.fade-content > div {
    overflow: hidden;
    transition: max-height 0.3s ease;
}

.fade-content > div.none {
    max-height: none;
}
</style>
