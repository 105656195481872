<script setup>
import { formatEther, formatUsd } from '../utils/numbers'
import { useWalletStore } from '../stores/wallet'

defineProps({
    account: {
        type: Object,
        required: true,
    },
})

const walletStore = useWalletStore()
</script>

<template>
    <div class="grid gap-x-10 gap-y-2.5 md:grid-cols-2">
        <div class="flex flex-col gap-2">
            <div
                v-if="account.address === walletStore.escrowAddress"
                class="flex justify-between"
            >
                <template v-if="walletStore.network === 'arbitrum'">
                    <div>Pending ETH</div>
                    <div class="text-zinc-200">
                        {{ formatEther(account.pending_ETH) }} ETH ({{
                            formatUsd(
                                account.pending_ETH,
                                walletStore.price.ETH_USD
                            )
                        }})
                    </div>
                </template>
                <template v-if="walletStore.network === 'avalanche'">
                    <div>Pending AVAX</div>
                    <div class="text-zinc-200">
                        {{ formatEther(account.pending_ETH) }} AVAX ({{
                            formatUsd(
                                account.pending_ETH,
                                walletStore.price.ETH_USD
                            )
                        }})
                    </div>
                </template>
            </div>

            <div class="flex justify-between">
                <div class="text-zinc-400">esGMX</div>
                <div class="text-zinc-200">
                    {{ formatEther(account.esGMX) }} GMX ({{
                        formatUsd(account.esGMX, walletStore.price.GMX_USD)
                    }})
                </div>
            </div>

            <div class="flex justify-between">
                <div class="text-zinc-400">Staked GMX</div>
                <div class="text-zinc-200">
                    {{ formatEther(account.GMX_staked) }} GMX ({{
                        formatUsd(
                            account.GMX_staked,
                            walletStore.price.GMX_USD
                        )
                    }})
                </div>
            </div>

            <div class="flex justify-between">
                <div class="text-zinc-400">Staked esGMX</div>
                <div class="text-zinc-200">
                    {{ formatEther(account.esGMX_staked) }} GMX ({{
                        formatUsd(
                            account.esGMX_staked,
                            walletStore.price.GMX_USD
                        )
                    }})
                </div>
            </div>

            <div class="flex justify-between">
                <div class="text-zinc-400">Pending esGMX</div>
                <div class="text-zinc-200">
                    {{ formatEther(account.pending_esGMX, 6) }} ({{
                        formatUsd(
                            account.pending_esGMX,
                            walletStore.price.GMX_USD
                        )
                    }})
                </div>
            </div>

            <div class="flex justify-between">
                <div class="text-zinc-400">GLP</div>
                <div class="text-zinc-200">
                    {{ formatEther(account.GLP) }} GLP
                    <template v-if="walletStore.price.GLP_USD">
                        ({{
                            formatUsd(account.GLP, walletStore.price.GLP_USD)
                        }})
                    </template>
                </div>
            </div>

            <hr class="my-2 border-zinc-700" />

            <div class="flex justify-between">
                <div
                    v-tippy="'GMX + esGMX'"
                    class="cursor-help text-zinc-400 underline decoration-dashed decoration-1 underline-offset-2"
                >
                    Total tokens
                </div>
                <div class="text-zinc-200">
                    {{
                        formatEther(
                            account.pending_esGMX.add(
                                account.esGMX_staked.add(
                                    account.GMX_staked.add(account.esGMX)
                                )
                            ),
                            2
                        )
                    }}
                </div>
            </div>
        </div>

        <hr class="my-2 border-zinc-700 md:hidden" />

        <div class="flex flex-col gap-2">
            <div class="flex justify-between">
                <div class="text-zinc-400">
                    <div
                        v-tippy="'earned from GMX/esGMX'"
                        class="cursor-help text-zinc-400 underline decoration-dashed decoration-1 underline-offset-2"
                    >
                        esGMX earned from GMX
                    </div>
                </div>
                <div class="text-zinc-200">
                    {{ formatEther(account.max_esGMX_vestable_GMX) }} GMX
                </div>
            </div>

            <div class="flex justify-between">
                <div
                    v-tippy="'1 Year'"
                    class="cursor-help text-zinc-400 underline decoration-dashed decoration-1 underline-offset-2"
                >
                    Tokens needed to vest
                </div>
                <div class="text-zinc-200">
                    {{ formatEther(account.tokens_to_vest) }} tokens
                </div>
            </div>

            <hr class="my-2 border-zinc-700" />

            <div class="flex justify-between">
                <div class="text-zinc-400">esGMX earned from GLP</div>
                <div class="text-zinc-200">
                    {{ formatEther(account.max_esGMX_vestable_GLP) }} esGMX
                </div>
            </div>

            <div class="flex justify-between">
                <div
                    v-tippy="'1 Year'"
                    class="cursor-help text-zinc-400 underline decoration-dashed decoration-1 underline-offset-2"
                >
                    GLP needed to vest
                </div>
                <div class="text-zinc-200">
                    {{ formatEther(account.glp_to_vest) }} GLP
                </div>
            </div>

            <hr class="my-2 border-zinc-700" />

            <div
                v-if="typeof account.sale_price !== 'undefined'"
                class="flex justify-between"
            >
                <div class="text-zinc-400">Sale price</div>
                <div class="text-zinc-200">
                    {{ formatEther(account.sale_price, 2) }} GMX ({{
                        formatUsd(
                            account.sale_price,
                            walletStore.price.GMX_USD
                        )
                    }})
                </div>
            </div>
        </div>
    </div>
</template>
