<script setup>
import { formatEther, formatUsd } from '../utils/numbers.js'
import { useWalletStore } from '../stores/wallet.js'
import { useListingsStore } from '../stores/listings.js'
import CoreButton from './CoreButton.vue'
import LoadingSpinner from './LoadingSpinner.vue'
import { truncatedAddress } from '../utils/address'
import { watch } from 'vue'
import CoreSelect from './CoreSelect.vue'
import { storeToRefs } from 'pinia'

defineProps({
    hideFilters: {
        type: Boolean,
        required: false,
        default: false,
    },
})
const walletStore = useWalletStore()
const listingsStore = useListingsStore()
const { sortBy } = storeToRefs(listingsStore)

const sortingOptions = [
    {
        label: 'None',
        value: '',
    },
    {
        label: 'esGMX',
        value: 'totalesgmx',
        params: {
            order: `totalesgmx.desc`,
        },
    },
    {
        label: 'GLP',
        value: 'glp',
        params: {
            order: `glpbal.desc`,
        },
    },
    // {
    //     label: 'Total tokens',
    //     value: 'totalValue',
    //     params: {
    //         order: `totaltokens.desc`,
    //     },
    // },
    {
        label: 'Sale price',
        value: 'salePrice',
        params: {
            order: `saleprice.desc`,
        },
    },
]

sortBy.value = sortingOptions[1]

watch(
    sortBy,
    (value) => {
        listingsStore.params = value?.params || {}
        listingsStore.fetchListings()
    },
    { immediate: true }
)

// function toggleSortOrder() {
//     listingsStore.sortOrder = listingsStore.sortOrder === 'asc' ? 'desc' : 'asc'
//     listingsStore.fetchListings()
// }
</script>

<template>
    <div
        v-if="hideFilters === false"
        class="mb-3 flex items-center justify-start"
    >
        <label class="flex items-center">
            <span class="mr-2">Sort by:</span>
            <CoreSelect v-model="sortBy" :options="sortingOptions"></CoreSelect>
        </label>

        <!--        <button @click="toggleSortOrder">-->
        <!--            {{ sortOrder }}-->
        <!--        </button>-->

        <!--        <label class="flex items-center">-->
        <!--            <span class="mr-2">Sort order:</span>-->
        <!--            <CoreSelect-->
        <!--                v-model="sortOrder"-->
        <!--                :options="[-->
        <!--                    { label: 'Ascending', value: 'asc' },-->
        <!--                    { label: 'Descending', value: 'desc' },-->
        <!--                ]"-->
        <!--            ></CoreSelect>-->
        <!--        </label>-->

        <div class="ml-4 h-5 w-5">
            <LoadingSpinner
                v-if="listingsStore.isLoading"
                class="h-5 w-5"
            ></LoadingSpinner>
        </div>
    </div>
    <div class="overflow-hidden">
        <div class="relative overflow-x-auto whitespace-nowrap rounded-md">
            <table class="table w-full text-left text-sm">
                <thead>
                    <tr>
                        <th class="bg-zinc-900 p-4 font-semibold">Account</th>
                        <th class="bg-zinc-900 p-4 font-semibold">GMX</th>
                        <th class="bg-zinc-900 p-4 font-semibold">esGMX</th>
                        <th class="bg-zinc-900 p-4 font-semibold">GLP</th>
                        <th class="bg-zinc-900 p-4 font-semibold">
                            Sale price
                        </th>
                        <th class="bg-zinc-900 p-4 font-semibold"></th>
                    </tr>
                </thead>

                <tbody>
                    <tr
                        v-for="account in listingsStore.getListings"
                        :key="account.address"
                        class="group border-t border-white border-opacity-10"
                    >
                        <td
                            class="bg-zinc-800 p-3 px-4 group-hover:bg-zinc-900"
                            :title="account.address"
                        >
                            <router-link
                                :to="{
                                    name: 'listing.show',
                                    params: { address: account.address },
                                }"
                                class="underline hover:text-blue-400"
                            >
                                {{ truncatedAddress(account.address) }}
                            </router-link>

                            <span
                                v-if="
                                    account.address ===
                                    walletStore.escrowAddress
                                "
                                class="ml-2 rounded-xl bg-blue-800 px-1 py-1 text-xs text-white"
                            >
                                Your account
                            </span>
                        </td>
                        <td
                            class="bg-zinc-800 p-3 px-4 group-hover:bg-zinc-900"
                        >
                            {{ formatEther(account.GMX_staked, 2) }}
                        </td>
                        <td
                            class="bg-zinc-800 p-3 px-4 group-hover:bg-zinc-900"
                        >
                            {{ formatEther(account.total_esGMX, 2) }}
                        </td>
                        <td
                            class="bg-zinc-800 p-3 px-4 group-hover:bg-zinc-900"
                        >
                            {{ formatEther(account.GLP) }}
                        </td>
                        <td
                            class="bg-zinc-800 p-3 px-4 group-hover:bg-zinc-900"
                        >
                            {{ formatEther(account.sale_price) }} GMX ({{
                                formatUsd(
                                    account.sale_price,
                                    walletStore.price.GMX_USD
                                )
                            }})
                        </td>
                        <td
                            class="bg-zinc-800 p-3 px-4 group-hover:bg-zinc-900"
                        >
                            <div class="flex gap-3">
                                <router-link
                                    v-slot="{ navigate }"
                                    :to="{
                                        name: 'listing.show',
                                        params: { address: account.address },
                                    }"
                                    custom
                                >
                                    <CoreButton
                                        color="light-gray"
                                        class="hover:!bg-blue-600"
                                        size="xs"
                                        @click="navigate"
                                    >
                                        View
                                    </CoreButton>
                                </router-link>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-if="listingsStore.total" class="my-3 px-4 text-zinc-300">
            Total listings:
            <strong>{{ listingsStore.total }}</strong>
        </div>
    </div>
    <div
        v-if="listingsStore.total === 0"
        class="mt-4 flex items-center justify-center italic"
    >
        No listings...
    </div>
    <div
        v-if="listingsStore.total > listingsStore.page * listingsStore.limit"
        class="mt-4 flex items-center justify-center"
    >
        <CoreButton :handler="listingsStore.nextPage" size="sm">
            Load more
        </CoreButton>
    </div>

    <div
        v-if="listingsStore.accounts.length === 0 && listingsStore.isLoading"
        class="my-2 flex w-full items-center justify-center gap-3"
    >
        <LoadingSpinner class="block h-6 w-6"></LoadingSpinner>
        Loading listings
    </div>
</template>
