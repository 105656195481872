<script setup>
import TransferAccount from './listing/add/TransferAccount.vue'
import ApproveAmount from './listing/add/ApproveAmount.vue'
import { useWalletStore } from '../stores/wallet'

const walletStore = useWalletStore()
</script>

<template>
    <div class="mx-auto max-w-screen-sm">
        <h1 class="my-8 text-2xl font-bold">Listing tools</h1>

        <div class="my-2">Escrow account: {{ walletStore.escrowAddress }}</div>
        <div class="my-2">Transferred: {{ walletStore.isTransferred }}</div>
        <div class="my-2">Approval amount: {{ walletStore.allowance }}</div>

        <ApproveAmount />

        <TransferAccount />
    </div>
</template>
