<script setup>
import { closeEscrow } from '../../../dataProvider.js'
import { useWalletStore } from '../../../stores/wallet.js'
import CoreButton from '../../../components/CoreButton.vue'
import toast from '../../../utils/toast.js'
import { BigNumber } from 'ethers'

const wallet = useWalletStore()

const emit = defineEmits(['close'])

function closeAccount() {
    return closeEscrow(wallet.escrowAddress)
        .then((res) => {
            return res.wait().then(() => {
                wallet.escrowAddress = null
                wallet.escrowAccount = null
                wallet.pendingReceivers = null
                wallet.allowance = BigNumber.from(0)

                emit('close')
            })
        })
        .catch((err) => {
            const error = err.error
            const message = error.data.message || error.message
            toast.error(message)
        })
}
</script>

<template>
    <div class="my-8 transition-opacity duration-300">
        <div class="text-xl font-semibold">Escrow account</div>
        <p class="mb-4 text-zinc-400">
            You need to close the previous escrow account before adding a
            listing.
        </p>

        <CoreButton color="red" class="w-full" :handler="closeAccount">
            Close escrow account
        </CoreButton>
    </div>
</template>
