<script setup>
import { ref, watch } from 'vue'
import { ethers } from 'ethers'
import { createListing } from '../../../dataProvider.js'
import { useWalletStore } from '../../../stores/wallet.js'
import CoreButton from '../../../components/CoreButton.vue'
import { useRouter } from 'vue-router'
import toast from '../../../utils/toast.js'
import { getFutureEscrowAddress } from '../../../dataProvider'
import { useWeb3ModalAccount } from '@web3modal/ethers5/vue'
import CoreInput from '../../../components/CoreInput.vue'
import { calculateFee } from '../../../readContracts'
import { debounce, throttle } from 'lodash'
import { formatEther } from '../../../utils/numbers'
import LoadingSpinner from '../../../components/LoadingSpinner.vue'

const salePrice = ref(null)

const { address, chainId, isConnected } = useWeb3ModalAccount()
const wallet = useWalletStore()
const router = useRouter()

async function setForSaleLocal() {
    const salePriceBigNumber = ethers.utils.parseUnits(
        salePrice.value.toString(),
        'ether'
    )

    const futureEscrow = await getFutureEscrowAddress(address.value)

    return createListing(futureEscrow.salt, salePriceBigNumber)
        .then((res) => {
            return res.wait().then(async () => {
                const address = futureEscrow.address
                toast.success('Account successfully listed')

                if ('gtag' in window && address) {
                    window.gtag('event', 'set_for_sale', {
                        transaction_id: address,
                        currency: 'USD',
                    })
                }

                return router.push({
                    name: 'listing.show',
                    params: { address: address },
                })
            })
        })
        .catch((err) => {
            console.error(err)
            const error = err.error
            const message = error.data.message || error.message
            toast.error(message)
        })
}

const feePercentage = ref(0)
const payout = ref(null)
const fees = ref(null)
const isLoadingFees = ref(false)
async function calculateFees() {
    if (!salePrice.value) {
        fees.value = null
        payout.value = null
        return
    }

    isLoadingFees.value = true

    const salePriceBigNumber = ethers.utils.parseUnits(
        salePrice.value.toString(),
        'ether'
    )

    const { FeeBP, Fees, Payout } = await calculateFee(
        address.value,
        salePriceBigNumber
    )

    fees.value = Fees
    payout.value = Payout

    feePercentage.value = FeeBP.toNumber() / 100

    isLoadingFees.value = false
}

watch(salePrice, debounce(calculateFees, 100))
</script>

<template>
    <div class="my-8 transition-opacity duration-300">
        <div class="text-xl font-semibold">Set for sale</div>
        <p class="mb-4 text-zinc-400">List escrow account for sale</p>

        <div
            class="flex w-full flex-col gap-2 rounded-lg border border-zinc-800 bg-zinc-900 p-5 text-zinc-400"
        >
            <div>
                <label class="block text-zinc-200" for="sale-price">
                    Sale price
                </label>
                <CoreInput
                    id="sale-price"
                    v-model="salePrice"
                    type="number"
                    placeholder="730"
                    suffix="GMX"
                    step="0.5"
                />
            </div>

            <p class="text-sm">
                You will receive
                <span class="text-zinc-300">
                    {{ formatEther(payout, 4) }} GMX
                </span>
                after

                <span
                    v-tippy="formatEther(fees, 4) + ' GMX'"
                    class="cursor-help text-zinc-300 underline decoration-dashed decoration-1 underline-offset-2"
                >
                    {{ feePercentage }}% fees
                </span>

                <LoadingSpinner
                    v-if="isLoadingFees"
                    class="ml-2 inline h-4 w-4"
                ></LoadingSpinner>
            </p>
        </div>

        <CoreButton class="mt-4 w-full" :handler="setForSaleLocal">
            List account
        </CoreButton>
    </div>
</template>
