<template>
    <vue-final-modal
        v-bind="$attrs"
        class="flex items-center justify-center"
        content-class="relative max-w-sm flex flex-col max-h-full mx-4 rounded bg-zinc-900 text-gray-100"
    >
        <ModalHeader :close="$attrs['click-to-close'] ? close : null">
            <slot name="title" />
        </ModalHeader>

        <slot name="body">
            <div class="flex-grow overflow-y-auto px-6 pb-6 pt-2">
                <slot />
            </div>
        </slot>

        <div v-if="$slots.footer" class="border-t border-gray-400 p-6">
            <slot name="footer" :close="close"></slot>
        </div>
    </vue-final-modal>
</template>

<script setup>
import { VueFinalModal } from 'vue-final-modal'
import ModalHeader from './ModalHeader.vue'

const emit = defineEmits(['close'])

function close() {
    emit('close')
}
</script>
