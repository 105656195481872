<script setup>
import { truncatedAddress } from '../../utils/address'
import { formatEther, formatUsd } from '../../utils/numbers'
import { useWalletStore } from '../../stores/wallet'
import { humanDate } from '../../utils/dayjs'
import { useListingsHistoryStore } from '../../stores/listingsHistory'
import HistoryChart from './HistoryChart.vue'
import DataCard from '../../components/DataCard.vue'
import CoreTable from '../../components/CoreTable.vue'
import { computed, ref, watch } from 'vue'
import BigNumber from 'bignumber.js'

const walletStore = useWalletStore()
const listingsHistoryStore = useListingsHistoryStore()

const fromDate = ref('2023-02-19')
const toDate = ref(new Date())

watch(
    [fromDate, toDate],
    () => {
        if (listingsHistoryStore.listings.length) {
            return
        }

        listingsHistoryStore.fetchListingsHistory(walletStore.network, {
            from: fromDate.value,
            to: toDate.value,
        })
    },
    { immediate: true, deep: true }
)

const avgPricePerGmx = computed(() => {
    const filteredListings = listingsHistoryStore.listings.filter(
        (listing) => listing.totalesgmx > 1
    )

    return filteredListings
        .reduce((acc, listing) => {
            const salePrice = new BigNumber(listing.saleprice)
            const totalEsGmx = new BigNumber(listing.totalesgmx)
            const gmxUsd = new BigNumber(listing.gmxusd)

            return acc.plus(
                salePrice.dividedBy(totalEsGmx).multipliedBy(gmxUsd)
            )
        }, new BigNumber(0))
        .dividedBy(filteredListings.length)
})

const sortedListings = computed(() => {
    return listingsHistoryStore.listings.sort((a, b) => b.endat - a.endat)
})

const isDev = import.meta.env.DEV
</script>

<template>
    <div class="mx-auto max-w-screen-xl">
        <div class="mb-6 mt-12">
            <h1 class="text-3xl font-bold text-zinc-100">Sale history</h1>
        </div>
    </div>

    <div
        v-if="isDev"
        class="mx-auto mb-12 grid max-w-screen-xl grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4"
    >
        <DataCard
            title="Total sales"
            :value="listingsHistoryStore.listings.length"
        ></DataCard>

        <DataCard
            title="Total volume"
            :value="formatUsd(listingsHistoryStore.totalVolumeInUSD, null, 0)"
        ></DataCard>

        <DataCard
            title="Total esGMX sold"
            :value="formatEther(listingsHistoryStore.totalEsGmxSold) + ' GMX'"
        ></DataCard>
    </div>

    <div
        v-if="listingsHistoryStore.listings.length"
        class="mx-auto my-10 max-w-screen-xl"
    >
        <!--        <div class="flex items-center gap-3">-->
        <!--            From-->
        <!--            <CoreInput v-model="fromDate" placeholder="From" type="date" />-->
        <!--            To-->
        <!--            <CoreInput v-model="toDate" placeholder="To" type="date" />-->
        <!--        </div>-->

        <HistoryChart
            :key="listingsHistoryStore.listings"
            :listings="listingsHistoryStore.listings"
            :start-date="fromDate"
            :end-date="toDate"
        />
    </div>

    <div class="mx-auto max-w-screen-xl">
        <CoreTable>
            <thead>
                <tr>
                    <th class="bg-zinc-900 p-4 font-semibold">Sold for</th>
                    <th class="bg-zinc-900 p-4 font-semibold">GMX</th>
                    <th class="bg-zinc-900 p-4 font-semibold">esGMX</th>
                    <th class="bg-zinc-900 p-4 font-semibold">GLP</th>
                    <th class="bg-zinc-900 p-4 font-semibold">MPs</th>
                    <th class="bg-zinc-900 p-4 font-semibold">Boost</th>
                    <th class="bg-zinc-900 p-4 font-semibold">
                        Escrow address
                    </th>
                    <th class="bg-zinc-900 p-4 font-semibold">
                        Seller address
                    </th>
                    <th class="bg-zinc-900 p-4 font-semibold">Sold at</th>
                </tr>
            </thead>

            <tbody>
                <tr
                    v-for="account in sortedListings"
                    :key="account.listingaddress"
                    class="group border-t border-white border-opacity-10"
                >
                    <td class="bg-zinc-800 p-3 px-4 group-hover:bg-zinc-900">
                        {{ formatEther(account.saleprice) }} GMX ({{
                            formatUsd(account.saleprice, account.gmxusd)
                        }})
                    </td>

                    <td class="bg-zinc-800 p-3 px-4 group-hover:bg-zinc-900">
                        {{ formatEther(account.stakedgmxbal) }}
                    </td>

                    <td class="bg-zinc-800 p-3 px-4 group-hover:bg-zinc-900">
                        {{ formatEther(account.totalesgmx) }}
                    </td>

                    <td class="bg-zinc-800 p-3 px-4 group-hover:bg-zinc-900">
                        {{ formatEther(account.glpbal) }}
                    </td>

                    <td class="bg-zinc-800 p-3 px-4 group-hover:bg-zinc-900">
                        {{ formatEther(account.totalmps) }}
                    </td>

                    <td class="bg-zinc-800 p-3 px-4 group-hover:bg-zinc-900">
                        {{
                            (account.boostpercentage * 100).toFixed(2) > 200
                                ? 200
                                : (account.boostpercentage * 100).toFixed(2)
                        }}%
                    </td>

                    <td class="bg-zinc-800 p-3 px-4 group-hover:bg-zinc-900">
                        <a
                            :href="`${walletStore.explorerUrl}/address/${account.listingaddress}`"
                            class="underline"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {{ truncatedAddress(account.listingaddress) }}
                        </a>
                    </td>

                    <td class="bg-zinc-800 p-3 px-4 group-hover:bg-zinc-900">
                        <a
                            :href="`${walletStore.explorerUrl}/address/${account.selleraddress}`"
                            class="underline"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {{ truncatedAddress(account.selleraddress) }}
                        </a>
                    </td>

                    <td class="bg-zinc-800 p-3 px-4 group-hover:bg-zinc-900">
                        <span
                            v-tippy="
                                new Date(
                                    account.endat * 1000
                                ).toLocaleDateString()
                            "
                            class="cursor-help"
                        >
                            {{ humanDate(account.endat * 1000, false) }}
                        </span>
                    </td>
                </tr>
            </tbody>
        </CoreTable>

        <!--        <div-->
        <!--            v-if="-->
        <!--                listingsHistoryStore.total >-->
        <!--                listingsHistoryStore.page * listingsHistoryStore.limit-->
        <!--            "-->
        <!--            class="mt-4 flex items-center justify-center"-->
        <!--        >-->
        <!--            <CoreButton :handler="listingsHistoryStore.nextPage" size="sm">-->
        <!--                Load more-->
        <!--            </CoreButton>-->
        <!--        </div>-->
    </div>
</template>
