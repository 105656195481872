import AppHome from './views/AppHome.vue'
import ListingShow from './views/listing/show/ListingShow.vue'
import ListingAdd from './views/listing/add/ListingAdd.vue'
import RoadmapOverview from './views/roadmap/RoadmapOverview.vue'
import FaqOverview from './views/faq/FaqOverview.vue'
import SoldListings from './views/listings-history/SoldListings.vue'
import IncomeCalculation from './views/IncomeCalculation.vue'
import PriceEstimator from './views/PriceEstimator.vue'
import ListingTools from './views/ListingTools.vue'

export default [
    { path: '/', component: AppHome, name: 'home' },
    {
        path: '/sold-listings',
        redirect: '/sale-history',
    },
    {
        path: '/sale-history',
        component: SoldListings,
        name: 'sale-history',
        meta: { title: 'Sale history' },
    },
    {
        path: '/listing/:address',
        component: ListingShow,
        name: 'listing.show',
        meta: { title: 'View account' },
    },
    {
        path: '/price-estimator',
        component: PriceEstimator,
        name: 'price-estimator',
        meta: { title: 'Price estimator' },
    },
    {
        path: '/list-account',
        component: ListingAdd,
        name: 'listing.create',
        meta: { title: 'Add listing' },
    },
    {
        path: '/income-calculator',
        component: IncomeCalculation,
        name: 'income-calculator',
        meta: { title: 'Income Calculator' },
    },
    {
        path: '/roadmap',
        component: RoadmapOverview,
        name: 'roadmap',
        meta: { title: 'Roadmap' },
    },
    {
        path: '/faq',
        component: FaqOverview,
        name: 'faq',
        meta: { title: 'FAQ' },
    },
    {
        path: '/listing-tools',
        component: ListingTools,
        meta: { title: 'Listing tools' },
    },
]
