<template>
    <div class="w-full rounded-md border border-zinc-800 bg-zinc-900 text-sm">
        <slot name="header">
            <div
                class="relative border-zinc-700 px-2 py-3 md:px-5"
                :class="{
                    'border-b': show,
                }"
            >
                <div class="flex justify-between text-lg font-bold text-white">
                    <slot name="title">
                        <h1>
                            {{ title }}
                        </h1>

                        <div v-if="expandable">
                            <ExpandIcon
                                class="h-8 w-8 cursor-pointer rounded-md p-1 transition-colors duration-300 hover:bg-zinc-800 active:bg-zinc-700"
                                :class="{
                                    'rotate-180': show,
                                }"
                                @click="show = !show"
                            />
                        </div>
                    </slot>
                </div>

                <div class="text-sm text-zinc-400">
                    <slot name="sub-title">
                        {{ subTitle }}
                    </slot>
                </div>
            </div>
        </slot>

        <slot name="content">
            <div v-show="show" class="p-2 pb-4 md:p-5 md:pb-6">
                <slot />
            </div>
        </slot>
    </div>
</template>

<script setup>
import { ChevronDownIcon as ExpandIcon } from '@heroicons/vue/24/outline'
import { ref } from 'vue'

defineProps({
    title: {
        type: String,
        required: false,
        default: '',
    },
    subTitle: {
        type: String,
        required: false,
        default: '',
    },
    expandable: {
        type: Boolean,
        required: false,
        default: false,
    },
})

const show = ref(true)
</script>
