import { useModal, useModalSlot } from 'vue-final-modal'
import Modal from '../components/modal/Modal.vue'
import ManageAccountModal from '../components/ManageAccountModal.vue'
import BuyAccountModal from '../components/BuyAccountModal.vue'
import { useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers5/vue'

export const useManageAccountModal = () => {
    const manageAccountModal = useModal({
        component: Modal,
        slots: {
            title: 'Manage account',
            default: useModalSlot({
                component: ManageAccountModal,
                attrs: {
                    onClose() {
                        manageAccountModal.close()
                    },
                },
            }),
        },
    })

    return {
        manageAccountModal,
    }
}

export const useBuyAccountModal = (listing) => {
    const accountAddressForSale = listing.address

    const buyAccountModal = useModal({
        component: Modal,
        attrs: {
            onBeforeOpen: onBeforeOpen,
        },
        slots: {
            title: 'Buy account',
            default: useModalSlot({
                component: BuyAccountModal,
                attrs: {
                    account: listing,
                    sellersAddress: accountAddressForSale,
                    onClose() {
                        buyAccountModal.close()
                    },
                },
            }),
        },
    })

    return {
        buyAccountModal,
    }
}

let onBeforeOpen = (e) => {
    const { isConnected } = useWeb3ModalAccount()
    const { open } = useWeb3Modal()

    if (isConnected.value === false) {
        e.stop()
        open()
    }
}
