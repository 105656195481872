<script setup>
import { BigNumber } from 'ethers'
import {
    approve,
    approveTransfer,
    checkAllowance,
    getChainId,
    getFutureEscrowAddress,
} from '../../../dataProvider.js'
import CoreButton from '../../../components/CoreButton.vue'
import { useWalletStore } from '../../../stores/wallet.js'
import { getAddress } from '../../../addresses'
import { useWeb3ModalAccount } from '@web3modal/ethers5/vue'
import { checkTransferApproval } from '../../../readContracts'

const { address, chainId, isConnected } = useWeb3ModalAccount()
const wallet = useWalletStore()
const stakedGmxTrackerAddress = getAddress(
    getChainId(wallet.network),
    'stakedGmxTracker'
)

const updateAllowance = () => {
    return checkAllowance(address.value, stakedGmxTrackerAddress).then(
        (res) => {
            wallet.allowance = res
        }
    )
}

const updateTransferApproval = async () => {
    const computedAddress = await getFutureEscrowAddress(address.value)

    wallet.transferApproval = await checkTransferApproval(
        address.value,
        computedAddress.address
    )
}

const approveTransferBalance = async () => {
    const computedAddress = await getFutureEscrowAddress(address.value)

    if (
        wallet.transferApproval.allowance.lt(
            wallet.transferApproval.approvalBalance
        )
    ) {
        return approveTransfer(
            computedAddress.address,
            wallet.transferApproval.approvalBalance
        ).then((res) => {
            res.wait().then(() => {
                updateTransferApproval()
            })
        })
    }
}

updateAllowance().then(() => {
    updateTransferApproval()
})

async function approveLocal() {
    let maxInt256 = BigNumber.from(2).pow(256).sub(1)

    const promise = await approve(stakedGmxTrackerAddress, maxInt256)

    return promise.wait().then(() => {
        return updateAllowance()
    })
}
</script>

<template>
    <div class="my-8 transition-opacity duration-300">
        <div class="text-xl font-semibold">Approve</div>
        <p class="mb-4 text-zinc-400">Create an GMX spending approval</p>

        <CoreButton
            v-if="wallet.allowance.isZero()"
            class="mt-4 w-full"
            :handler="approveLocal"
        >
            Approve
        </CoreButton>

        <CoreButton
            v-else
            class="mt-4 w-full"
            :handler="approveTransferBalance"
        >
            Approve transfer
        </CoreButton>
    </div>
</template>
