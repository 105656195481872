<script setup>
import { useWalletStore } from '../../../stores/wallet'
import CoreButton from '../../../components/CoreButton.vue'
import { computed } from 'vue'
import { useManageAccountModal } from '../../../composers/modals'

const props = defineProps({
    address: {
        type: String,
        required: true,
    },
})

const walletStore = useWalletStore()
const { manageAccountModal } = useManageAccountModal()

const isYourListing = computed(() => {
    return walletStore.escrowAddress === props.address
})

const openModal = () => {
    if ('gtag' in window) {
        window.gtag('event', 'manage_listing_open', {
            event_category: 'manage_listing',
        })
    }

    manageAccountModal.open()
}
</script>

<template>
    <CoreButton
        v-if="isYourListing"
        size="sm"
        class="mt-4 flex-shrink-0 disabled:opacity-50 md:mt-0"
        @click="openModal"
    >
        Manage
        <template v-if="walletStore.getEscrowAccountState === 'listed'">
            listing
        </template>
        <template v-else>account</template>
    </CoreButton>
</template>
