<template>
    <div class="mt-12 flex flex-col items-center gap-10">
        <h1 class="text-3xl font-bold text-zinc-100">Income Calculator</h1>

        <div class="grid max-w-xl grid-cols-2 gap-4">
            <label>
                GLP
                <br />
                <CoreInput v-model="glpAmount" placeholder="GLP" />
            </label>
            <label>
                GLP APR %
                <br />
                <CoreInput v-model="glpApr" placeholder="GLP APR" />
            </label>

            <label>
                GMX
                <br />
                <CoreInput v-model="gmxAmount" placeholder="GMX" />
            </label>
            <label>
                GMX APR %
                <br />
                <CoreInput v-model="gmxApr" placeholder="GMX APR" />
            </label>
        </div>

        <EstimateIncome
            :glp="glpAmount"
            :gmx="gmxAmount"
            :glp-apr="glpApr"
            :gmx-apr="gmxApr"
        />
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useWalletStore } from '../stores/wallet'
import CoreInput from '../components/CoreInput.vue'
import { getApr } from '../apr'
import { getChainId } from '../dataProvider'
import EstimateIncome from '../components/EstimateIncome.vue'

const walletStore = useWalletStore()
const apr = getApr(getChainId(walletStore.network))

const gmxAmount = ref(100)
const glpAmount = ref(1200)
const boost = ref(0)
const glpApr = ref(0)
const gmxApr = ref(0)

apr.then((res) => {
    glpApr.value = res.glp
    gmxApr.value = res.gmx
})
</script>
