<script setup>
import ListingsTable from '../components/ListingsTable.vue'
import CoreButton from '../components/CoreButton.vue'
import { useListingsHistoryStore } from '../stores/listingsHistory'
import { formatUsd } from '../utils/numbers'
import { useWalletStore } from '../stores/wallet'
import axios from 'axios'
import { ref } from 'vue'

const listingsHistoryStore = useListingsHistoryStore()
const walletStore = useWalletStore()
listingsHistoryStore.fetchListingsHistory(walletStore.network)

const totalv1USD = ref(0)

axios.get('https://api.sntl.market/sold').then((res) => {
    const listings = res.data

    totalv1USD.value = listings.reduce((acc, listing) => {
        return acc + Number(listing.gmxusd) * Number(listing.saleprice)
    }, 0)
})
</script>

<template>
    <div
        class="mx-auto flex max-w-screen-xl grid-cols-12 flex-col gap-10 lg:grid lg:flex-col xl:gap-12"
    >
        <div class="lg:col-span-3">
            <div class="lg:mt-4">
                <h1 class="pb-4 text-left text-3xl font-semibold text-white">
                    A decentralized market for esGMX
                </h1>
                <p class="text-zinc-300">
                    Buy, sell and trade GMX accounts. Including
                    <span class="font-bold">esGMX</span>
                    <span>!</span>
                </p>
            </div>

            <div class="my-4 text-sm">
                Total volume:
                <span
                    v-if="listingsHistoryStore.totalVolumeInUSD"
                    class="font-semibold"
                >
                    {{
                        formatUsd(
                            listingsHistoryStore.totalVolumeInUSD,
                            null,
                            0
                        )
                    }}
                </span>
            </div>

            <div class="mt-6 flex gap-4">
                <router-link
                    v-slot="{ navigate }"
                    :to="{
                        name: 'listing.create',
                    }"
                    custom
                >
                    <CoreButton @click="navigate">Add listing</CoreButton>
                </router-link>

                <router-link v-slot="{ navigate }" to="/sale-history" custom>
                    <CoreButton color="gray" @click="navigate">
                        Sale history
                    </CoreButton>
                </router-link>
            </div>
        </div>

        <div class="lg:col-span-9">
            <ListingsTable />
        </div>
    </div>
</template>
