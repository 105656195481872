<script setup>
import { useSanityFetcher } from 'vue-sanity'
import { SanityBlocks } from 'sanity-blocks-vue-component'
import LoadingSpinner from '../../components/LoadingSpinner.vue'
import dayjs from '../../utils/dayjs'

const { data } = useSanityFetcher(`*[_type == "roadmap"] | order(date asc)`)
</script>

<template>
    <div class="mx-auto mt-12 grid max-w-7xl gap-4 md:grid-cols-12">
        <div class="col-span-4">
            <h1 class="text-2xl font-bold">Our roadmap</h1>
            <p class="text-zinc-300">Follow planned projects here</p>
        </div>

        <div class="col-span-8">
            <div
                class="mb-12 rounded-lg border border-zinc-800 bg-zinc-900 p-6 text-sm text-zinc-400"
            >
                <div
                    v-if="data === null"
                    class="flex w-full items-center justify-center py-10"
                >
                    <LoadingSpinner class="h-10 w-10" />
                </div>

                <ol class="space-y-7">
                    <li v-for="item in data" :key="item._id" class="pl-8">
                        <div class="relative">
                            <h2 class="text-lg font-semibold text-zinc-200">
                                {{ item.name }}
                            </h2>
                            <span
                                :class="{
                                    'bg-green-500': item.color === 'green',
                                    'bg-blue-500': item.color === 'blue',
                                    'bg-red-500': item.color === 'red',
                                }"
                                class="absolute -left-8 bottom-0 top-0 m-auto inline-block flex h-5 w-5 rounded-full bg-opacity-50"
                            >
                                <span
                                    :class="{
                                        'bg-green-500': item.color === 'green',
                                        'bg-blue-500': item.color === 'blue',
                                        'bg-red-500': item.color === 'red',
                                    }"
                                    class="m-auto inline-block h-3 w-3 rounded-full"
                                ></span>
                            </span>
                        </div>
                        <time
                            v-if="item.date && !item.hideDate"
                            class="block text-sm"
                            :content="item.date"
                        >
                            {{ dayjs(item.date).format('YYYY-MM') }}
                        </time>

                        <div v-if="item.content" class="mt-1.5 leading-loose">
                            <SanityBlocks :blocks="item.content"></SanityBlocks>
                        </div>
                    </li>
                </ol>
            </div>
        </div>
    </div>
</template>
