import { BigNumber as EthersBigNumber, ethers } from 'ethers'
import Decimal from 'decimal.js'

const DECIMAL_18 = new Decimal(10).pow(18)
const DECIMAL_6 = new Decimal(10).pow(6)

export const formatEther = (number, decimals = 2, l = 18) => {
    const toEthersBigNumber = (val) =>
        EthersBigNumber.from(new Decimal(10).pow(l).mul(val).toHex())

    if (typeof number === 'number') {
        number = toEthersBigNumber(number)
    }

    if (!number || number.isZero()) {
        return '0'
    }

    const formattedNumber = ethers.utils.formatEther(number)

    if (!decimals) {
        return formattedNumber
    }

    const minEthToShow = ethers.utils.parseUnits(
        `0.${'0'.repeat(decimals - 1)}1`,
        'ether'
    )

    if (number.gte(minEthToShow)) {
        return new Intl.NumberFormat('en-US', {
            maximumFractionDigits: decimals,
        }).format(Number(formattedNumber))
    }

    return `<0.${'0'.repeat(decimals - 1)}1`
}

export const formatUSDC = (number) => {
    const n = (number.toString() / 10 ** 6).toFixed(2)

    return Intl.NumberFormat('en-US').format(n)
}

export const formatUsd = (number, usd = null, decimals = 2) => {
    if (isNaN(number) || !number) {
        number = 0
    }

    if (typeof number === 'number') {
        const numberAsString = number.toString().slice(0, 16)

        number = ethers.utils.parseEther(number ? numberAsString : '0')
    }

    if (!number) {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: decimals,
        }).format(0)
    }

    const formattedNumber = ethers.utils.formatEther(number)
    const result = usd ? formattedNumber * usd : formattedNumber

    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: decimals,
    }).format(result)
}
