<script setup>
import CoreTable from '../../../../components/CoreTable.vue'
import CoreButton from '../../../../components/CoreButton.vue'
import { truncatedAddress } from '../../../../utils/address'
import { getEscrowAccountOffers } from '../../../../readContracts'
import { computed, ref, watch } from 'vue'
import { formatEther } from 'ethers/lib/utils'
import { formatUsd } from '../../../../utils/numbers'
import { useWalletStore } from '../../../../stores/wallet'
import {
    acceptEscrowAccountOffer,
    cancelEscrowAccountOffer,
} from '../../../../dataProvider'
import toast from '../../../../utils/toast'
import MakeOffer from './MakeOffer.vue'
import { useWeb3ModalAccount } from '@web3modal/ethers5/vue'

const props = defineProps({
    listingAddress: {
        type: String,
        required: true,
    },
})

const { address, chainId, isConnected } = useWeb3ModalAccount()
const walletStore = useWalletStore()
const offers = ref(null)

watch(
    () => props.listingAddress,
    async (newVal) => {
        if (!newVal) return

        offers.value = await getEscrowAccountOffers(newVal)
    },
    { immediate: true }
)

const acceptOffer = (offer) => {
    return acceptEscrowAccountOffer(
        props.listingAddress,
        offer.buyer,
        offer.amount
    ).then(async (res) => {
        toast.success('Offer accepted')

        if ('gtag' in window) {
            window.gtag('event', 'purchase', {
                event_category: 'offers',
            })
        }

        return res.wait().then(async () => {
            // Remove offer from list
            offers.value = offers.value.filter((o) => o.buyer !== offer.buyer)
        })
    })
}

const hasOffer = computed(() => {
    return offers.value?.some((o) => o.buyer === address.value)
})

const addOffer = (offer) => {
    const index = offers.value.findIndex((o) => o.buyer === offer.buyer)
    if (index !== -1) {
        // Find existing offer and replace
        offers.value[index] = offer
    } else {
        // Add new offer
        offers.value.push(offer)
    }
}

const cancelOffer = (offer) => {
    return cancelEscrowAccountOffer(props.listingAddress, offer.buyer).then(
        async (res) => {
            toast.success('Offer cancelled')
            return res.wait().then(async () => {
                // Remove offer from list
                offers.value = offers.value.filter(
                    (o) => o.buyer !== offer.buyer
                )
            })
        }
    )
}
</script>

<template>
    <div v-if="offers === null">Loading offers...</div>
    <div v-else-if="offers.length === 0">No offers yet...</div>
    <CoreTable v-else-if="offers.length > 0">
        <thead class="text-white">
            <tr>
                <th class="py-1 pr-4 font-semibold">From</th>
                <th class="py-1 pr-4 font-semibold">Bid</th>
                <th class="py-1 pr-4 font-semibold"></th>
            </tr>
        </thead>

        <tbody>
            <tr v-for="offer in offers" :key="offer.buyer">
                <td class="py-1.5 pr-4">
                    <a
                        :href="`${walletStore.explorerUrl}/address/${offer.buyer}`"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {{ truncatedAddress(offer.buyer) }}
                    </a>
                </td>
                <td class="py-1.5 pr-4">
                    {{ formatEther(offer.amount) }} GMX ({{
                        formatUsd(offer.amount, walletStore.price.GMX_USD)
                    }})
                </td>
                <td class="py-1.5 pr-4">
                    <div class="flex justify-end gap-3">
                        <CoreButton
                            v-if="walletStore.escrowAddress === listingAddress"
                            size="xs"
                            color="blue"
                            @click="acceptOffer(offer)"
                        >
                            Accept
                        </CoreButton>

                        <CoreButton
                            v-if="
                                walletStore.escrowAddress === listingAddress ||
                                address === offer.buyer
                            "
                            size="xs"
                            color="light-gray"
                            @click="cancelOffer(offer)"
                        >
                            Cancel
                        </CoreButton>
                    </div>
                </td>
            </tr>
        </tbody>
    </CoreTable>

    <MakeOffer
        v-if="listingAddress !== walletStore.escrowAddress"
        :listing-address="listingAddress"
        :has-offer="hasOffer"
        class="mt-5"
        @offer-created="addOffer($event)"
    />
</template>
