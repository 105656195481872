import { defineStore } from 'pinia'
import { truncatedAddress } from '../utils/address.js'
import * as ethers from 'ethers'
import { BigNumber } from 'ethers'
import { getChainId, getSellersEscrow } from '../dataProvider.js'
import {
    checkTransfer,
    getGMXMarketListingDetails,
    getLatestBlock,
    getPendingReceivers,
    getTokenPrices,
} from '../readContracts'
import {
    useWeb3Modal,
    useWeb3ModalAccount,
    useWeb3ModalProvider,
    useWeb3ModalState,
} from '@web3modal/ethers5/vue'
import { ARBITRUM, AVALANCHE } from '../addresses'
import { useLocalStorage } from '@vueuse/core'
import { getAccount, switchNetwork } from '@wagmi/core'

export const useWalletStore = defineStore('wallet', {
    state: () => {
        return {
            selectedNetwork: useLocalStorage('selected-network', ARBITRUM),
            escrowAddress: null,
            escrowAccount: null,
            // escrowAddress: useLocalStorage(
            //     'escrow-address-' + getNetwork(),
            //     null
            // ),
            // escrowAccount: useLocalStorage(
            //     'escrow-account-' + getNetwork(),
            //     null
            // ),
            pendingReceivers: null,
            transferApproval: {
                allowance: BigNumber.from(0),
                approvalBalance: BigNumber.from(0),
            },
            allowance: BigNumber.from(0),
            allowanceUSDC: BigNumber.from(0),
            isTransferred: false,
            isLoadingEscrow: false,
            latestBlock: null,
            price: {
                ETH_USD: null,
                GMX_USD: null,
                GMX_ETH: null,
                GLP_USD: null,
            },
        }
    },

    getters: {
        chainId(store) {
            if (store.selectedNetwork === ARBITRUM) {
                return ARBITRUM
            }

            if (store.selectedNetwork === AVALANCHE) {
                return AVALANCHE
            }
        },
        network(store) {
            // const { chainId } = useWeb3ModalAccount()

            if (store.selectedNetwork === ARBITRUM) {
                return 'arbitrum'
            }

            if (store.selectedNetwork === AVALANCHE) {
                return 'avalanche'
            }
        },
        address() {
            const { address } = useWeb3ModalAccount()
            return address.value || ''
        },
        explorerUrl() {
            if (this.network === 'arbitrum') {
                return 'https://arbiscan.io'
            }

            if (this.network === 'avalanche') {
                return 'https://snowtrace.io'
            }
        },
        truncatedAddress(store) {
            return truncatedAddress(store.address)
        },
        getEscrowAccountState(store) {
            const account = store.escrowAccount
            const latestBlock = store.latestBlock

            if (!account || !latestBlock) {
                return null
            }

            // if (
            //     !this.escrowAddress ||
            //     this.escrowAddress ===
            //         '0x0000000000000000000000000000000000000000'
            // ) {
            //     return null
            // }

            /*
1. EscrowAccount.SalePrice=0 AND EscrowAccount.EndAt=0
2. EscrowAccount.SalePrice ≠ 0 AND EscrowAccount.EndAt > current block time
3. EscrowAccount.SalePrice ≠ 0 AND EscrowAccount.EndAt <= current block time
4. EscrowAccount.SalePrice = 0 AND EscrowAccount.EndAt <= current block time AND EscrowAccount.IsSold = true
5. EscrowAccount.IsSold = false AND EscrowAccount.SaleIneligible = false AND EscrowAccount.IsPurchased = true
6. EscrowAccount.IsSold = false AND EscrowAccount.SaleIneligible = true AND EscrowAccount.IsPurchased = true
            */

            // const isSold = await escrowAccountIsSold(this.escrowAddress)
            // const saleIneligible = await escrowAccountSaleIneligible(
            //     this.escrowAddress
            // )
            // const isPurchased = await escrowAccountIsPurchased(
            //     this.escrowAddress
            // )

            // State 4a
            if (account.sale_price?.eq(0) && account.is_sold === true) {
                return 'listed_sold'
            }

            // State 5
            if (
                account.is_sold === false &&
                account.sale_ineligible === false &&
                account.is_purchased === true
            ) {
                return 'purchased'
            }

            if (account.is_sold === false && account.sale_ineligible === true) {
                if (
                    store.pendingReceivers.toLowerCase() ===
                    store.address.toLowerCase()
                ) {
                    // connected account, show Complete Transfer button
                    return 'purchased_pending'
                } else if (
                    store.pendingReceivers !== ethers.constants.AddressZero
                ) {
                    // show message (something like "Please switch connected address to the receiving address to complete transfer of GMX account out of Escrow account")
                    return 'purchased_pending_other'
                } else if (
                    store.pendingReceivers === ethers.constants.AddressZero
                ) {
                    // show Close Escrow button
                    return 'purchased_pending_empty'
                }
            }

            // State 6
            // if (
            //     account.is_sold === false &&
            //     account.sale_ineligible === true &&
            //     account.is_purchased === true
            // ) {
            //     return 'purchased_transferred'
            // }

            // State 1
            if (!account.sale_price?.toString()) {
                return 'created'
            }

            // State 4b
            if (account.sale_price?.eq(0) && account.is_sold === false) {
                return 'listed_ended'
            }

            // State 2
            if (!account.sale_price?.eq(0)) {
                return 'listed'
            }

            return null
        },
        getEscrowAccountStateText(store) {
            const state = store.getEscrowAccountState

            if (state === 'created') {
                return 'Escrow account created'
            }

            if (state === 'listed') {
                return 'Listed'
            }

            if (state === 'listed_expired') {
                return 'Listing has expired'
            }

            if (state === 'listed_ended') {
                return 'Listing has ended. You can now transfer it to another address or relist.'
            }

            if (state === 'listed_sold') {
                return 'Listing has been sold'
            }

            if (state === 'purchased') {
                return 'Account purchased'
            }

            if (state === 'purchased_pending') {
                return 'Account purchased, pending transfer to GMX'
            }

            if (state === 'purchased_pending_other') {
                return 'Account transferred to another address.'
            }

            if (state === 'purchased_pending_empty') {
                return 'Account purchased and transferred out of escrow'
            }

            if (state === 'purchased_transferred') {
                return 'Escrow account purchased, transferred'
            }

            return null
        },
    },

    actions: {
        pricePromise() {
            const self = this
            return new Promise((resolve) => {
                if (!self.price.ETH_USD) {
                    getTokenPrices().then((res) => {
                        self.price = {
                            ETH_USD: res.ETH_USD,
                            GMX_USD: res.GMX_USD,
                            GMX_ETH: res.GMX_ETH,
                            GLP_USD: res.GLP_USD,
                        }
                        resolve(self.price)
                    })
                } else {
                    resolve(self.price)
                }
            })
        },
        getLatestBlock() {
            return getLatestBlock()
        },
        getTransferredStatus() {
            return checkTransfer(this.address).then((res) => {
                return !(!res || res === ethers.constants.AddressZero)
            })
        },
        async changeNetwork(chainId) {
            const { isConnected } = useWeb3ModalAccount()

            if (!isConnected.value && !window.ethereum) {
                this.selectedNetwork = chainId
                return window.location.reload()
            }

            const provider = useWeb3ModalProvider()
            const walletProvider =
                provider.walletProvider.value || window.ethereum

            walletProvider
                .request({
                    method: 'wallet_switchEthereumChain',
                    params: [
                        {
                            chainId: `0x${chainId.toString(16)}`,
                        },
                    ],
                })
                .then(() => {
                    this.selectedNetwork = chainId
                    window.location.reload()
                })
                .catch((err) => {
                    console.error(err)
                })
        },
        async updateEscrowAccount() {
            console.log('Updating escrow account...')
            if (!this.address) {
                return Promise.resolve(null)
            }

            this.isLoadingEscrow = true

            const latestBlockPromise = this.getLatestBlock()
            const transferredStatusPromise = this.getTransferredStatus()
            const sellerEscrowPromise = getSellersEscrow(this.address).then(
                async (escrowAddress) => {
                    if (escrowAddress === null) {
                        this.escrowAddress = null
                        this.escrowAccount = null
                        this.isLoadingEscrow = false
                        return
                    }

                    this.escrowAddress = escrowAddress
                    this.pendingReceivers = await getPendingReceivers(
                        escrowAddress
                    )

                    return getGMXMarketListingDetails(escrowAddress)
                }
            )

            Promise.all([
                latestBlockPromise,
                transferredStatusPromise,
                sellerEscrowPromise,
            ])
                .then((res) => {
                    this.latestBlock = res[0]
                    this.isTransferred = res[1]
                    this.escrowAccount = res[2]
                })
                .finally(() => {
                    this.isLoadingEscrow = false
                })
        },
    },
})

export const defaultAccountListing = {
    GMX_staked: BigNumber.from(0),
    esGMX: BigNumber.from(0),
    esGMX_staked: BigNumber.from(0),
    max_esGMX_vestable_GMX: BigNumber.from(0),
    max_esGMX_vestable_GLP: BigNumber.from(0),
    tokens_to_vest: BigNumber.from(0),
    glp_to_vest: BigNumber.from(0),
    GLP: BigNumber.from(0),
    pending_ETH: BigNumber.from(0),
    pending_esGMX: BigNumber.from(0),
    sale_price: BigNumber.from(0),
    total_esGMX: BigNumber.from(0),
    total_mps: BigNumber.from(0),
    total_tokens: BigNumber.from(0),
}
