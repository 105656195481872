import dayjs from 'dayjs'
// import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'

// dayjs.extend(duration)
dayjs.extend(relativeTime)

export const humanDate = (unixTime, withoutSuffix = false) => {
    return dayjs(unixTime).fromNow(withoutSuffix)
}

export default dayjs
