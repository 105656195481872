<script setup>
import { useRoute } from 'vue-router'
import { ref } from 'vue'
import { defaultAccountListing, useWalletStore } from '../../../stores/wallet'
import AccountDetails from '../../../components/AccountDetails.vue'
import { getGMXMarketListingDetails } from '../../../readContracts'
import ListingOffers from './offers/ListingOffers.vue'
import { getApr } from '../../../apr'
import { getChainId } from '../../../dataProvider'
import ListingHeader from './ListingHeader.vue'
import PriceEstimatorComponent from '../../../components/PriceEstimatorComponent.vue'
import ListingsTable from '../../../components/ListingsTable.vue'
import ReadMoreComponent from '../../../components/ReadMoreComponent.vue'
import CoreCard from '../../../components/CoreCard.vue'
import EstimateIncome from '../../../components/EstimateIncome.vue'
import { formatEther } from '../../../utils/numbers'
import { ethers } from 'ethers'

const route = useRoute()
const walletStore = useWalletStore()

const listingAddress = route.params.address

const isLoading = ref(true)
const glpApr = ref(0)
const gmxApr = ref(0)
const listing = ref(defaultAccountListing)

getApr(getChainId(walletStore.network)).then((res) => {
    glpApr.value = res.glp
    gmxApr.value = res.gmx
})

getGMXMarketListingDetails(listingAddress).then(async (res) => {
    listing.value = res
    isLoading.value = false
})
</script>

<template>
    <div class="mx-auto max-w-screen-md space-y-10">
        <CoreCard>
            <template #header>
                <ListingHeader :listing="listing" :address="listingAddress" />
            </template>

            <AccountDetails v-if="!isLoading" :account="listing" />
        </CoreCard>

        <CoreCard v-if="!isLoading" title="Offers">
            <ListingOffers :listing-address="listingAddress" />
        </CoreCard>

        <!--        <EstimateIncome-->
        <!--            v-if="-->
        <!--                listing.GLP?.gt(ethers.utils.parseUnits('500')) ||-->
        <!--                listing.total_gmx?.gt(ethers.utils.parseUnits('10'))-->
        <!--            "-->
        <!--            :glp="Number(formatEther(listing.GLP))"-->
        <!--            :gmx="Number(formatEther(listing.total_gmx, 2))"-->
        <!--            :glp-apr="glpApr"-->
        <!--            :gmx-apr="gmxApr"-->
        <!--            :boost="listing.boost"-->
        <!--        />-->
        <!--        <div v-else class="text-center text-sm italic">-->
        <!--            Income estimation is not available for this account.-->
        <!--        </div>-->

        <CoreCard class="mx-auto max-w-3xl" title="Price estimator">
            <div class="flex flex-col gap-10">
                <PriceEstimatorComponent :listing="listing" />

                <ReadMoreComponent class="space-y-4 leading-loose">
                    <p>
                        Default multipliers are set to what the community has
                        historically priced GMX accounts at, and may not reflect
                        current market conditions. Accounts are non-fungible, so
                        many factors go into pricing them. Multipliers in this
                        calculator can be changed to represent alternative
                        pricing structures.
                    </p>

                    <p>Factors that may impact price typically include:</p>

                    <ul class="list-disc pl-4">
                        <li>Size of account</li>
                        <li>Vesting requirements</li>
                        <li>
                            Presence or lack thereof of staked GMX on the
                            account
                        </li>
                        <li>Price of GMX -Market sentiment</li>
                    </ul>

                    <p>
                        It is recommended to list your account at a high price
                        and allow for prospective buyers to either place offers
                        directly on the SNTL DApp, and/or link up with them in
                        our
                        <a
                            class="underline"
                            href="https://t.me/sntlmarkets"
                            target="_blank"
                        >
                            Telegram
                        </a>
                        to negotiate a price.
                    </p>
                </ReadMoreComponent>
            </div>
        </CoreCard>

        <CoreCard title="More listings" class="mx-auto max-w-3xl">
            <template #content>
                <ListingsTable hide-filters />
            </template>
        </CoreCard>
    </div>
</template>
