<template>
    <div
        v-if="currentBanner"
        class="p-2 text-center text-sm text-white"
        :class="{
            'bg-green-900': currentBanner.color === 'green',
            'bg-blue-900': currentBanner.color === 'blue',
            'bg-red-700': currentBanner.color === 'red',
        }"
    >
        <SanityBlocks :blocks="currentBanner.content"></SanityBlocks>
    </div>
</template>

<script setup>
import { fetch } from 'vue-sanity'
import { SanityBlocks } from 'sanity-blocks-vue-component'
import { ref } from 'vue'

const currentBanner = ref(null)
let query = `*[_type == "banners" && version == 3] | order(_createdAt desc) [0]`

fetch(query).then((res) => {
    currentBanner.value = res
})
</script>
