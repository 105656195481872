import { ARBITRUM, AVALANCHE } from './addresses'

export const getApr = async (network) => {
    return Promise.resolve(
        {
            [ARBITRUM]: {
                gmx: 3.11,
                glp: 12.52,
            },
            [AVALANCHE]: {
                gmx: 3.11,
                glp: 10.6,
            },
        }[network]
    )
}
