<script setup>
import { MenuItem } from '@headlessui/vue'

defineProps({
    to: {
        type: [String, Object],
        required: true,
    },
})

function onMobileItemClick(event, close, navigate) {
    event.preventDefault()
    close()
    navigate()
}
</script>

<template>
    <div>
        <router-link v-slot="{ href, navigate, isActive }" :to="to" custom>
            <MenuItem v-slot="{ close }">
                <a
                    :href="href"
                    class="block rounded-lg px-3 pt-2 pb-2 text-2xl font-semibold text-white text-opacity-30"
                    :class="{
                        'text-opacity-100': isActive,
                    }"
                    @click="onMobileItemClick($event, close, navigate)"
                >
                    <slot />
                </a>
            </MenuItem>
        </router-link>
    </div>
</template>
