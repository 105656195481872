import axios from 'axios'
import * as ethers from 'ethers'
import { BigNumber as EthersBigNumber } from 'ethers'
import { ARBITRUM, AVALANCHE, getAddress } from './addresses'
import GMXMarket from './../abis/GMXMarket.json'
import GMX from './../abis/GMX.json'
import USDC from './../abis/USDC.json'
import EscrowAccount from './../abis/EscrowAccount.json'
import EscrowAccountAvalanche from './../abis/avalanche/EscrowAccount.json'
import GMXRewardRouter from './../abis/GMXRewardRouter.json'
import sbfGMXAbi from './../abis/sbfGMX.json'
import Quoter from './../abis/Quoter.json'
import QuoterAvalanche from './../abis/avalanche/Quoter.json'
import BigNumber from 'bignumber.js'
import Decimal from 'decimal.js'
import { configureChains, fetchSigner } from '@wagmi/core'
// 4. Use modal composable
import { arbitrum, avalanche } from '@wagmi/core/chains'
import { generateSalt } from './readContracts'
import { useWeb3ModalProvider } from '@web3modal/ethers5/vue'
import { useWalletStore } from './stores/wallet'

const { JsonRpcProvider } = ethers.providers

export const providers = {
    // arbitrum: new JsonRpcProvider('https://arb1.arbitrum.io/rpc'),
    arbitrum: new JsonRpcProvider(
        'https://arb-mainnet.g.alchemy.com/v2/oKWTfm3eZYUcs-jRY7Yzq-eAH0ZIwOw-'
    ),
    avalanche: new JsonRpcProvider('https://api.avax.network/ext/bc/C/rpc'),
}

function getProvider() {
    const walletStore = useWalletStore()
    const chainName = walletStore.network

    if (!(chainName in providers)) {
        throw new Error(`Unknown chain ${chainName}`)
    }
    return providers[chainName]
}

export function getChainId() {
    const walletStore = useWalletStore()

    return walletStore.chainId
}

function getSigner() {
    const provider = useWeb3ModalProvider()
    const walletProvider = provider.walletProvider.value
    const ethersProvider = new ethers.providers.Web3Provider(walletProvider)

    return ethersProvider.getSigner()
}

export const checkAllowance = async (
    ownerAddress,
    spenderAddress,
    token = 'GMX'
) => {
    const provider = getProvider()
    const chainId = getChainId()

    if (token === 'GMX') {
        const GMXContract = new ethers.Contract(
            getAddress(chainId, 'GMX'),
            GMX.abi,
            provider
        )

        return GMXContract.allowance(ownerAddress, spenderAddress)
    }

    if (token === 'USDC') {
        const USDCContract = new ethers.Contract(
            getAddress(chainId, 'USDC'),
            USDC.abi,
            provider
        )

        return USDCContract.allowance(ownerAddress, spenderAddress)
    }
}

export const approveTransfer = async (
    computedEscrowAddress,
    approvalBalance
) => {
    const signer = getSigner()
    const chainId = getChainId()

    const feeGmxTrackerContract = new ethers.Contract(
        getAddress(chainId, 'sbfGMX'),
        sbfGMXAbi.abi,
        signer
    )

    return feeGmxTrackerContract.approve(computedEscrowAddress, approvalBalance)
}

export const approve = async (address, price, token = 'GMX') => {
    const chainId = getChainId()
    const signer = await getSigner()

    if (token === 'USDC') {
        const USDCContract = new ethers.Contract(
            getAddress(chainId, 'USDC'),
            USDC.abi,
            signer
        )

        return USDCContract.approve(address, price)
    }

    if (token === 'GMX') {
        const GMXContract = new ethers.Contract(
            getAddress(chainId, 'GMX'),
            GMX.abi,
            signer
        )

        return GMXContract.approve(address, price)
    }
}

export const purchaseListingOnAvalanche = async (
    sellersAddress,
    transferToGMX = true,
    token = 'gmx',
    binstep1 = EthersBigNumber.from(0),
    binstep2 = EthersBigNumber.from(0),
    version1 = EthersBigNumber.from(0),
    version2 = EthersBigNumber.from(0),
    priceInSelectedToken = EthersBigNumber.from(0)
) => {
    const signer = await getSigner()
    let abi = EscrowAccountAvalanche.abi

    const EscrowAccountContract = new ethers.Contract(
        sellersAddress,
        abi,
        signer
    )

    let options = {}

    // GMX is default
    let tokenIndicator = null

    if (token === 'GMX') {
        tokenIndicator = 0
        options.value = 0
    }

    if (token === 'AVAX') {
        tokenIndicator = 1
        options.value = priceInSelectedToken
    }

    if (token === 'USDC') {
        tokenIndicator = 2
        options.value = 0
    }

    if (tokenIndicator === null) {
        throw Error('Invalid token to buy with')
    }

    return EscrowAccountContract.MakePurchase(
        transferToGMX,
        tokenIndicator,
        binstep1 || EthersBigNumber.from(0),
        binstep2 || EthersBigNumber.from(0),
        EthersBigNumber.from(version1),
        EthersBigNumber.from(version2 || 0),
        priceInSelectedToken,
        options
    )
}

export const purchaseListingOnArbitrum = async (
    sellersAddress,
    buyersAddress,
    priceInGmx,
    transferToGMX = true,
    token = 'gmx',
    poolFee = 0, // BigNumber,
    priceInSelectedToken = EthersBigNumber.from(0)
) => {
    const signer = await getSigner()
    let abi = EscrowAccount.abi
    const chainId = getChainId()

    if (chainId === AVALANCHE) {
        abi = EscrowAccountAvalanche.abi
    }

    const EscrowAccountContract = new ethers.Contract(
        sellersAddress,
        abi,
        signer
    )

    let options = {}

    // GMX is default
    let tokenIndicator = null

    if (token === 'GMX') {
        tokenIndicator = 0
        options.value = 0
    }

    if (token === 'ETH') {
        tokenIndicator = 1
        options.value = priceInSelectedToken
    }

    if (token === 'USDC') {
        tokenIndicator = 2
        options.value = 0
    }

    if (tokenIndicator === null) {
        throw Error('Invalid token to buy with')
    }

    if (chainId === ARBITRUM) {
        return EscrowAccountContract.MakePurchase(
            transferToGMX,
            tokenIndicator,
            tokenIndicator ? poolFee : 0,
            token === 'GMX' ? priceInGmx : priceInSelectedToken,
            options
        )
    }
}

export const transferOut = async (escrowAddress, transferToAddress) => {
    const signer = await getSigner()

    const EscrowAccountContract = new ethers.Contract(
        escrowAddress,
        EscrowAccount.abi,
        signer
    )

    return EscrowAccountContract.TransferOut(transferToAddress)
}

export const closeEscrow = async (escrowAddress) => {
    const signer = await getSigner()

    const EscrowAccountContract = new ethers.Contract(
        escrowAddress,
        EscrowAccount.abi,
        signer
    )

    return EscrowAccountContract.CloseEscrow()
}

export const endEscrowEarly = async (escrowAddress) => {
    const signer = await getSigner()

    const EscrowAccountContract = new ethers.Contract(
        escrowAddress,
        EscrowAccount.abi,
        signer
    )

    return EscrowAccountContract.EndEarly()
}

export const changePrice = async (address, price) => {
    const signer = await getSigner()

    const Contract = new ethers.Contract(address, EscrowAccount.abi, signer)

    return Contract.ChangePrice(price)
}

export const acceptTransfer = async (buyersEscrowAddress) => {
    const chainId = getChainId()
    const signer = await getSigner()

    const GMXRewardRouterContract = new ethers.Contract(
        getAddress(chainId, 'GMXRewardRouter'),
        GMXRewardRouter.abi,
        signer
    )

    return GMXRewardRouterContract.acceptTransfer(buyersEscrowAddress)
}

export const getGMXMarketListings = async (
    page = 1,
    limit = 50,
    params = {}
) => {
    const offset = (page - 1) * limit

    let apiUrl = null
    const walletStore = useWalletStore()
    const chainName = walletStore.network

    if (chainName === 'arbitrum') {
        apiUrl = 'https://api.sntl.market/listingsv3'
    }

    if (chainName === 'avalanche') {
        apiUrl = 'https://avaxapi.sntl.market/listingsv3'
    }

    if (!apiUrl) {
        throw new Error(`Unknown chain: ${chainName}`)
    }

    const accounts = await axios.get(apiUrl, {
        params: {
            limit: limit,
            offset: offset,
            ...params,
        },
        headers: {
            'Range-Unit': 'items',
            Range: `${offset}-${offset + limit - 1}`,
            Prefer: 'count=exact',
        },
    })

    const total = Number(accounts.headers['content-range'].split('/')[1])
    const accountsData = accounts.data.map((listing) => {
        const format = (val) => {
            const decimal = new Decimal(val.toString()).mul(
                new Decimal(10).pow(18)
            )

            return EthersBigNumber.from(decimal.toHex())
        }

        let pendingEarnings = null

        if (chainName === 'arbitrum') {
            pendingEarnings = listing.pendingwethbal
        }

        if (chainName === 'avalanche') {
            pendingEarnings = listing.pendingwavaxbal
        }

        return {
            address: listing.listingaddress,
            GMX_staked: format(listing.stakedgmxbal.toString()),
            esGMX: format(listing.esgmxbal.toString()),
            esGMX_staked: format(listing.stakedesgmxbal.toString()),
            max_esGMX_vestable_GMX: format(
                listing.esgmxmaxvestgmxbal.toString()
            ),
            max_esGMX_vestable_GLP: format(
                listing.esgmxmaxvestglpbal.toString()
            ),
            tokens_to_vest: format(listing.tokenstovest.toString()),
            glp_to_vest: format(listing.glptovest.toString()),
            GLP: format(listing.glpbal.toString()),
            pending_ETH: format(pendingEarnings.toString()),
            pending_esGMX: format(listing.pendingesgmxbal.toString()),
            sale_price: format(listing.saleprice.toString()),
            total_esGMX: format(listing.totalesgmx.toString()),
            total_mps: format(listing.totalmps.toString()),
            total_tokens: format(listing.totaltokens.toString()),
        }
    })

    return {
        data: accountsData,
        meta: {
            total,
        },
    }
}

export const deployAccount = async (address) => {
    const chainId = getChainId()
    const signer = await getSigner()

    const GMXMarketContract = new ethers.Contract(
        getAddress(chainId, 'GMXMarket'),
        GMXMarket.abi,
        signer
    )

    const escrowAddress = await GMXMarketContract.EscrowsToOwners(address)

    if (escrowAddress !== ethers.constants.AddressZero) {
        return Promise.resolve(escrowAddress)
    }

    return GMXMarketContract.Deploy()
}

export const getSellersEscrow = async (sellersAddress) => {
    const chainId = getChainId()
    const provider = getProvider()

    const GMXMarketContract = new ethers.Contract(
        getAddress(chainId, 'GMXMarket'),
        GMXMarket.abi,
        provider
    )

    const address = await GMXMarketContract.Escrows(sellersAddress)

    if (!address || address === ethers.constants.AddressZero) {
        return null
    }

    return address
}

export const signalTransfer = async (sellersEscrowAddress) => {
    const chainId = getChainId()
    const signer = await getSigner()

    const GMXRewardRouterContract = new ethers.Contract(
        getAddress(chainId, 'GMXRewardRouter'),
        GMXRewardRouter.abi,
        signer
    )

    try {
        return GMXRewardRouterContract.signalTransfer(sellersEscrowAddress)
    } catch (e) {
        throw new Error('Signal transfer failed')
    }
}

export const setForSale = async (escrowAddress, salePrice) => {
    const signer = await getSigner()
    const chainId = getChainId()
    let abi = null

    if (chainId === AVALANCHE) {
        abi = EscrowAccountAvalanche.abi
    }

    if (chainId === ARBITRUM) {
        abi = EscrowAccount.abi
    }

    const EscrowAccountContract = new ethers.Contract(
        escrowAddress,
        abi,
        signer
    )

    return EscrowAccountContract.SetForSale(salePrice)
}

export const createListing = async (salt, salePrice) => {
    const chainId = getChainId()
    const signer = await getSigner()

    const GMXMarketContract = new ethers.Contract(
        getAddress(chainId, 'GMXMarket'),
        GMXMarket.abi,
        signer
    )

    return GMXMarketContract.List(salt, salePrice)
}

async function getAvaxQuote(chainId, provider, price, token) {
    const QuoteContract = new ethers.Contract(
        getAddress(chainId, 'Quoter'),
        QuoterAvalanche.abi,
        provider
    )

    const usdcAddress = getAddress(chainId, 'USDC')
    const avaxAddress = getAddress(chainId, 'WAVAX')
    const gmxAddress = getAddress(chainId, 'GMX')

    let inputAddress = null

    if (token === 'USDC') {
        inputAddress = [usdcAddress, avaxAddress]
    }

    if (token === 'AVAX') {
        inputAddress = [avaxAddress]
    }

    const quoteExactOutputSingle = (val) => {
        return QuoteContract.findBestPathFromAmountOut(
            [...inputAddress, gmxAddress],
            val
        )
    }

    const quote = await quoteExactOutputSingle(price)

    return {
        binstep1: quote.binSteps[0],
        binstep2: quote.binSteps[1],
        version1:
            typeof quote.versions[0] === 'undefined' ? null : quote.versions[0],
        version2:
            typeof quote.versions[1] === 'undefined' ? null : quote.versions[1],
        price: addMarginToPrice(quote.amounts[0]),
        priceWithoutMargin: quote.amounts[0],
    }
}

/**
 * Add 0.001% to the price
 *
 * @param {BigNumber} price
 * @return {BigNumber}
 */
export function addMarginToPrice(price) {
    return price.add(price.div(EthersBigNumber.from(10).pow(5)))
}

async function getEthQuote(chainId, provider, price) {
    const QuoteContract = new ethers.Contract(
        getAddress(chainId, 'Quoter'),
        Quoter.abi,
        provider
    )
    const weth = getAddress(chainId, 'WETH')
    const gmx = getAddress(chainId, 'GMX')

    // prettier-ignore
    const quoteExactOutputSingle = (val, salePrice) => {
        return QuoteContract.callStatic.quoteExactOutputSingle(weth, gmx, val, salePrice, 0)
    }

    const promise1 = quoteExactOutputSingle(3000, price)
    const promise2 = quoteExactOutputSingle(10000, price)
    const promise3 = quoteExactOutputSingle(3000, price.div(2))
    const promise4 = quoteExactOutputSingle(10000, price.div(2))
    const promise5 = quoteExactOutputSingle(3000, price.div(4))
    const promise6 = quoteExactOutputSingle(10000, price.div(4))
    const promise7 = quoteExactOutputSingle(3000, price.mul(75).div(100))
    const promise8 = quoteExactOutputSingle(10000, price.mul(75).div(100))

    const response = await Promise.all([
        promise1,
        promise2,
        promise3,
        promise4,
        promise5,
        promise6,
        promise7,
        promise8,
    ])

    const pools = {
        3000: response[0],
        10000: response[1],
        5050: response[2].add(response[3]),
        7525: response[5].add(response[6]),
        2575: response[4].add(response[7]),
    }
    return pools
}

async function getUsdcQuote(chainId, provider, price) {
    const QuoteContract = new ethers.Contract(
        getAddress(chainId, 'Quoter'),
        Quoter.abi,
        provider
    )

    const weth = getAddress(chainId, 'WETH')
    const usdc = getAddress(chainId, 'USDC')
    const gmx = getAddress(chainId, 'GMX')

    const pool3000 = ethers.utils.solidityPack(
        ['address', 'uint24', 'address', 'uint24', 'address'],
        [gmx, 3000, weth, 500, usdc]
    )

    const pool10000 = ethers.utils.solidityPack(
        ['address', 'uint24', 'address', 'uint24', 'address'],
        [gmx, 10000, weth, 500, usdc]
    )

    const quoteExactOutputSingle = (val, salePrice) => {
        return QuoteContract.callStatic.quoteExactOutput(val, salePrice)
    }

    const promise1 = quoteExactOutputSingle(pool3000, price)
    const promise2 = quoteExactOutputSingle(pool10000, price)
    const promise3 = quoteExactOutputSingle(pool3000, price.div(2))
    const promise4 = quoteExactOutputSingle(pool10000, price.div(2))
    const promise5 = quoteExactOutputSingle(pool3000, price.div(4))
    const promise6 = quoteExactOutputSingle(pool10000, price.div(4))
    const promise7 = quoteExactOutputSingle(pool3000, price.mul(75).div(100))
    const promise8 = quoteExactOutputSingle(pool10000, price.mul(75).div(100))

    const response = await Promise.all([
        promise1,
        promise2,
        promise3,
        promise4,
        promise5,
        promise6,
        promise7,
        promise8,
    ])

    return {
        3000: response[0],
        10000: response[1],
        5050: response[2].add(response[3]),
        7525: response[5].add(response[6]),
        2575: response[4].add(response[7]),
    }
}

/**
 * Get quote for converting GMX to WETH
 *
 * @param {BigNumber} price
 * @param {string} token
 */
export const getQuote = async (price, token) => {
    const provider = getProvider()
    const chainId = getChainId()

    let pools = null

    if (chainId === AVALANCHE) {
        if (token === 'AVAX' || token === 'USDC') {
            return getAvaxQuote(chainId, provider, price, token)
        }
    }

    if (token === 'ETH') {
        pools = await getEthQuote(chainId, provider, price)
    }

    if (token === 'USDC' && chainId === ARBITRUM) {
        pools = await getUsdcQuote(chainId, provider, price)
    }

    const min = EthersBigNumber.from(
        BigNumber.min(
            ...Object.values(pools).map((val) => {
                return new BigNumber(val.toString())
            })
        ).toString()
    )

    const result = {
        pool: null,
        price: null,
        priceWithoutMargin: null,
    }

    Object.entries(pools).find(([key, value]) => {
        if (value.eq(min)) {
            result.pool = key
            result.price = addMarginToPrice(value)
            result.priceWithoutMargin = value
        }
    })

    return result
}

export const createEscrowAccountOffer = async (escrowAddress, amount) => {
    const signer = await getSigner()

    const EscrowAccountContract = new ethers.Contract(
        escrowAddress,
        EscrowAccount.abi,
        signer
    )

    return EscrowAccountContract.MakeOffer(amount)
}

export const acceptEscrowAccountOffer = async (
    escrowAddress,
    buyerAddress,
    amount
) => {
    const signer = await getSigner()

    const EscrowAccountContract = new ethers.Contract(
        escrowAddress,
        EscrowAccount.abi,
        signer
    )

    return EscrowAccountContract.AcceptOffer(buyerAddress, amount)
}

export const cancelEscrowAccountOffer = async (escrowAddress, offerAddress) => {
    const signer = await getSigner()

    const EscrowAccountContract = new ethers.Contract(
        escrowAddress,
        EscrowAccount.abi,
        signer
    )

    return EscrowAccountContract.CancelOffer(offerAddress)
}

export const getFutureEscrowAddress = async (walletAddress) => {
    const chainId = getChainId()
    const provider = await getSigner()
    const GMXMarketContract = new ethers.Contract(
        getAddress(chainId, 'GMXMarket'),
        GMXMarket.abi,
        provider
    )

    const salt = await generateSalt(walletAddress)
    const address = await GMXMarketContract.ComputeFutureEscrowAddress(salt)

    return Promise.resolve({
        salt,
        address,
    })
}
