import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/vue'
import { ARBITRUM, AVALANCHE } from './addresses'
import { useWalletStore } from './stores/wallet'

export default function modal() {
    const projectId = '7a229e8a0200ab7b87cb600a69009e76'

    const arbitrumChain = {
        chainId: 42161,
        rpcUrl: 'https://arb1.arbitrum.io/rpc',
        name: 'Arbitrum',
        currency: 'ETH',
        explorerUrl: 'https://arbiscan.io/',
    }

    const avalancheChain = {
        chainId: 43114,
        rpcUrl: 'https://api.avax.network/ext/bc/C/rpc',
        name: 'Avalanche',
        currency: 'AVAX',
        explorerUrl: 'https://snowtrace.io/',
    }

    // 3. Create modal
    const metadata = {
        name: 'SNTL',
        description: 'SNTL – decentralized market for esGMX',
        url: 'https://sntl.market',
        icons: ['https://sntl.market/assets/logo-6b60669c.svg'],
    }

    return createWeb3Modal({
        ethersConfig: defaultConfig({ metadata }),
        chains: [arbitrumChain, avalancheChain],
        projectId,
    })
}
