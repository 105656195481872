<script setup>
import { ChartBarSquareIcon } from '@heroicons/vue/24/outline'

import { useWalletStore } from './stores/wallet'
import { getAddress } from './addresses'
import { getChainId } from './dataProvider'
const walletStore = useWalletStore()
</script>

<template>
    <footer class="mt-auto py-4 text-zinc-300">
        <div
            class="mx-auto mt-auto flex max-w-screen-xl flex-row flex-wrap items-center justify-center gap-2 py-5"
        >
            <a
                class="group flex items-center gap-2 px-3 py-2 text-left text-white text-opacity-50 transition hover:text-opacity-100"
                href="https://t.me/sntlmarkets"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img
                    class="mr-0.5 h-5 w-5 opacity-50 group-hover:opacity-100"
                    src="./assets/telegram-logo.svg"
                    alt="Telegram logo"
                />
                Telegram
            </a>

            <a
                class="group flex items-center gap-2 px-3 py-2 text-left text-white text-opacity-50 transition hover:text-opacity-100"
                href="https://twitter.com/sntlai"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img
                    class="mr-0.5 h-5 w-5 opacity-50 group-hover:opacity-100"
                    src="./assets/twitter-logo.svg"
                    alt="Twitter logo"
                />
                Twitter
            </a>

            <a
                class="group flex items-center gap-2 px-3 py-2 text-left text-white text-opacity-50 transition hover:text-opacity-100"
                href="https://github.com/SentinelDev1/SNTL-Market/"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img
                    class="mr-0.5 h-5 w-5 opacity-50 group-hover:opacity-100"
                    src="./assets/github-mark-white.svg"
                    alt="GitHub mark logo"
                />
                GitHub
            </a>

            <a
                class="group flex items-center gap-2 px-3 py-2 text-left text-white text-opacity-50 transition hover:text-opacity-100"
                :href="`${walletStore.explorerUrl}/address/${getAddress(
                    getChainId(),
                    'GMXMarket'
                )}#code`"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img
                    class="mr-0.5 h-5 w-5 rounded-full bg-white p-0.5 opacity-50 group-hover:opacity-100"
                    src="./assets/solidity-logo.svg"
                    alt="Solidity logo"
                />
                Contract
            </a>

            <a
                class="group flex items-center gap-2 px-3 py-2 text-left text-white text-opacity-50 transition hover:text-opacity-100"
                href="https://github.com/SentinelDev1/SNTL-Market/blob/main/SNTLMarketV2_AuditReport_InterFi.pdf"
                target="_blank"
                rel="noopener noreferrer"
            >
                <span class="mr-0.5">✅</span>
                Audit report
            </a>

            <router-link
                class="group flex items-center gap-2 px-3 py-2 text-left text-white text-opacity-50 transition hover:text-opacity-100"
                to="/sale-history"
            >
                <ChartBarSquareIcon
                    class="mr-0.5 h-5 w-5 opacity-50 group-hover:opacity-100"
                ></ChartBarSquareIcon>
                Sale history
            </router-link>
        </div>
    </footer>
</template>
