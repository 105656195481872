import { createApp, watch } from 'vue'
import { createPinia } from 'pinia'
import './style.css'
import App from './App.vue'
import { createVfm } from 'vue-final-modal'
import VueTippy from 'vue-tippy'
import 'vue-final-modal/style.css'
import 'tippy.js/dist/tippy.css'
import router from './router.js'
import modal from './walletConnect.js'
import { useWalletStore } from './stores/wallet'
import { ethers } from 'ethers'
import {
    useWeb3ModalAccount,
    useWeb3ModalProvider,
} from '@web3modal/ethers5/vue'
import * as Sentry from '@sentry/vue'
;(async function () {
    const pinia = createPinia()
    const app = createApp(App)
    const vfm = createVfm()

    app.use(pinia)

    const res = await modal().getWalletConnectProvider()
    const account = useWeb3ModalAccount()

    // Dirty hack to force a reload when the chainId changes
    watch(account.chainId, (newVal, oldVal) => {
        const walletStore = useWalletStore()
        walletStore.selectedNetwork = newVal
        if (newVal !== oldVal) {
            window.location.reload()
        }
    })

    Sentry.init({
        app,
        dsn: 'https://2c18e2b0e53dd6716c2d7e08cefc6cd7@o4507781772476416.ingest.de.sentry.io/4507781773721680',
        integrations: [],
    })

    app.use(router)
    app.use(vfm)
    app.use(VueTippy)
    app.mount('#app')
})()
