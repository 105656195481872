<template>
    <div class="overflow-hidden">
        <div class="relative overflow-x-auto whitespace-nowrap rounded-md">
            <table class="table w-full text-left text-sm">
                <slot />
            </table>
        </div>
    </div>
</template>

<script setup></script>
