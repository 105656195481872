import { defineStore } from 'pinia'
import { getGMXMarketListings } from '../dataProvider'

export const useListingsStore = defineStore('listings', {
    state: () => {
        return {
            addresses: [],
            accounts: [],

            page: 1,
            limit: 100,
            total: null,
            params: {},
            sortBy: '',
            sortOrder: 'asc',

            isLoading: false,
        }
    },

    getters: {
        getListings(store) {
            return store.accounts
        },
        getAddresses(store) {
            return store.accounts.map((account) => account.address)
        },
    },

    actions: {
        async fetchListings() {
            this.isLoading = true

            const { data, meta } = await getGMXMarketListings(
                this.page,
                this.limit,
                this.params
            ).finally(() => {
                this.isLoading = false
            })

            this.accounts = data
            this.total = meta.total
        },
        async nextPage() {
            this.page++

            this.isLoading = true
            const res = await getGMXMarketListings(this.page, this.limit)
            this.accounts.push(...res.data)
            this.isLoading = false

            return res
        },
    },
})
