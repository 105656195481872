<script setup lang="ts">
import CoreButton from '../components/CoreButton.vue'
import {
    ArrowTopRightOnSquareIcon,
    EllipsisHorizontalIcon,
    MapIcon,
    PlusIcon,
    BriefcaseIcon,
    ServerIcon,
} from '@heroicons/vue/24/outline'

import { ARBITRUM, AVALANCHE } from '../addresses.js'
import { useWalletStore } from '../stores/wallet.js'
import { MenuItem } from '@headlessui/vue'
import WalletConnect from '../components/WalletConnect.vue'
import RelativeDropdownMenu from '../components/RelativeDropdownMenu.vue'
import MobileNavigation from './MobileNavigation.vue'
import { useWeb3ModalAccount } from '@web3modal/ethers5/vue'

const walletStore = useWalletStore()
const { isConnected } = useWeb3ModalAccount()
</script>

<template>
    <nav class="px-4">
        <div class="mx-auto flex w-full max-w-screen-xl items-center py-4">
            <div class="flex flex-col gap-1 md:flex-row md:items-end lg:gap-3">
                <router-link to="/" class="group relative flex-shrink-0">
                    <img
                        src="../assets/logo.svg"
                        alt="SNTL Logo"
                        width="102"
                        height="21"
                        class="w-20 md:w-auto"
                    />

                    <span
                        class="absolute right-0 top-0 -translate-y-1/2 translate-x-full text-sm text-zinc-300 transition-colors group-hover:text-white"
                    >
                        V3
                    </span>

                    <span class="text-xs leading-none text-zinc-100 md:text-sm">
                        esGMX Market
                    </span>
                </router-link>
            </div>

            <nav
                class="flex flex-1 items-center justify-center gap-2 text-zinc-400 lg:gap-4"
            >
                <router-link
                    :to="{ name: 'home' }"
                    class="hidden px-3 py-3 text-white text-opacity-50 hover:text-opacity-100 md:inline"
                    active-class="!text-opacity-90"
                >
                    Listings
                </router-link>

                <router-link
                    :to="{ name: 'sale-history' }"
                    class="hidden px-3 py-3 text-white text-opacity-50 hover:text-opacity-100 md:inline"
                    active-class="!text-opacity-90"
                >
                    Sale history
                </router-link>

                <router-link
                    class="hidden px-3 py-3 text-white text-opacity-50 hover:text-opacity-100 md:inline"
                    active-class="!text-opacity-90"
                    to="/faq"
                >
                    Faq
                </router-link>
            </nav>

            <div class="flex justify-end gap-2">
                <router-link
                    v-if="walletStore.escrowAddress"
                    v-slot="{ navigate }"
                    :to="{
                        name: 'listing.show',
                        params: { address: walletStore.escrowAddress },
                    }"
                    custom
                >
                    <CoreButton
                        size="sm"
                        color="blue"
                        class="hidden h-9 sm:block"
                        @click="navigate"
                    >
                        My account
                    </CoreButton>
                </router-link>

                <MobileNavigation class="md:hidden" />

                <WalletConnect />

                <RelativeDropdownMenu>
                    <template #trigger>
                        <div
                            class="flex h-9 items-center justify-center rounded-md border border-zinc-700 px-2 transition hover:border-zinc-100"
                        >
                            <template v-if="walletStore.network === 'arbitrum'">
                                <img
                                    class="mr-1 h-5 w-5"
                                    src="../assets/arbitrum-logo.svg"
                                    alt="Arbitrum logo"
                                />
                            </template>

                            <template
                                v-if="walletStore.network === 'avalanche'"
                            >
                                <img
                                    class="mr-1 h-5 w-5"
                                    src="../assets/avalanche-logo.svg"
                                    alt="Avalanche logo"
                                />
                            </template>
                            <EllipsisHorizontalIcon
                                class="h-6 w-6 shrink-0 text-zinc-100"
                            />
                        </div>
                    </template>

                    <div>
                        <MenuItem>
                            <div>
                                <template
                                    v-if="walletStore.network === 'arbitrum'"
                                >
                                    <button
                                        class="flex w-full items-center gap-2 px-3 py-2 text-white text-opacity-50 hover:text-opacity-100"
                                        @click="
                                            walletStore.changeNetwork(AVALANCHE)
                                        "
                                    >
                                        <img
                                            class="h-5 w-5"
                                            src="../assets/avalanche-logo.svg"
                                            alt="Avalanche logo"
                                        />
                                        Switch to Avalanche
                                    </button>
                                </template>

                                <template
                                    v-if="walletStore.network === 'avalanche'"
                                >
                                    <button
                                        class="flex w-full items-center gap-2 px-3 py-2 text-white text-opacity-50 hover:text-opacity-100"
                                        @click="
                                            walletStore.changeNetwork(ARBITRUM)
                                        "
                                    >
                                        <img
                                            class="h-5 w-5"
                                            src="../assets/arbitrum-logo.svg"
                                            alt="Arbitrum logo"
                                        />

                                        Switch to Arbitrum
                                    </button>
                                </template>
                            </div>
                        </MenuItem>

                        <MenuItem v-if="isConnected">
                            <a
                                class="flex w-full items-center gap-2 px-3 py-2 text-white text-opacity-50 hover:text-opacity-100"
                                :href="`${walletStore.explorerUrl}/address/${walletStore.address}`"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <ArrowTopRightOnSquareIcon class="h-5 w-5" />
                                View in explorer
                            </a>
                        </MenuItem>

                        <MenuItem>
                            <router-link
                                class="flex w-full items-center gap-2 px-3 py-2 text-white text-opacity-50 hover:text-opacity-100"
                                :to="{
                                    name: 'listing.create',
                                }"
                            >
                                <PlusIcon class="h-5 w-5" />
                                Add listing
                            </router-link>
                        </MenuItem>

                        <MenuItem>
                            <router-link
                                class="flex w-full items-center gap-2 px-3 py-2 text-white text-opacity-50 hover:text-opacity-100"
                                to="/roadmap"
                            >
                                <MapIcon class="h-5 w-5" />
                                Roadmap
                            </router-link>
                        </MenuItem>

                        <MenuItem>
                            <a
                                class="flex w-full items-center gap-2 px-3 py-2 text-white text-opacity-50 hover:text-opacity-100"
                                href="https://v1.sntl.market"
                            >
                                <ServerIcon class="h-5 w-5" />
                                V1
                            </a>
                        </MenuItem>

                        <MenuItem>
                            <a
                                class="flex w-full items-center gap-2 px-3 py-2 text-white text-opacity-50 hover:text-opacity-100"
                                href="https://github.com/SentinelDev1/SNTL-Market/blob/main/SNTLMarket_AuditReport_InterFi.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <BriefcaseIcon class="h-5 w-5" />
                                Audit report
                            </a>
                        </MenuItem>

                        <MenuItem>
                            <a
                                class="flex w-full items-center gap-2 px-3 py-2 text-white text-opacity-50 hover:text-opacity-100"
                                href="https://t.me/sntlmarkets"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src="../assets/telegram-logo.svg"
                                    class="h-5 w-5"
                                    alt=""
                                />
                                Telegram
                            </a>
                        </MenuItem>
                    </div>
                </RelativeDropdownMenu>
            </div>
        </div>
    </nav>
</template>
