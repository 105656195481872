import Toastify from 'toastify-js'
import '../styles/toastify.css'

const defaultToast = (message, options = {}) => {
    if (!message) {
        throw Error('No message was provided')
    }

    const t = Toastify({
        text: message,
        duration: 5000,
        close: true,
        gravity: 'bottom',
        position: 'right',
        stopOnFocus: true,
        offset: {
            x: 15,
            y: 15,
        },
        ...options,
    })

    t.showToast()

    return t
}

export default {
    success(message, options = {}) {
        return defaultToast(message, {
            className: 'bg-green-700 text-white',
            ...options,
        })
    },
    info(message, options = {}) {
        return defaultToast(message, {
            className: 'bg-blue-700 text-white',
            ...options,
        })
    },
    error(message, options = {}) {
        return defaultToast(
            message ||
                'Something went wrong. Please reload the page and try again',
            {
                ...options,
                ...{
                    className: 'bg-red-500 text-white',
                },
            }
        )
    },
}
