<template>
    <div class="text-center">
        <div>
            <div class="text-zinc-400">
                <span>Estimated&nbsp;</span>
                <span
                    v-tippy="'Rewards in ETH'"
                    class="cursor-help underline decoration-dashed decoration-1 underline-offset-2"
                >
                    <span>income</span>
                </span>
                <span>&nbsp;per week</span>
            </div>
            <div class="mt-2 text-xl font-semibold">
                {{ revenue.usdFormatted }}
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { useWalletStore } from '../stores/wallet'

const props = defineProps({
    glp: {
        type: Number,
        required: true,
    },
    gmx: {
        type: Number,
        required: true,
    },
    glpApr: {
        type: Number,
        required: true,
    },
    gmxApr: {
        type: Number,
        required: true,
    },
})

const walletStore = useWalletStore()
const revenue = computed(() =>
    calculateApr(props.glp, props.glpApr, props.gmx, props.gmxApr)
)

function calculateApr(glp, glpApr, gmx, gmxApr) {
    const gmxPrice = walletStore.price.GMX_USD
    const glpPrice = walletStore.price.GLP_USD

    /**
     * GLP
     */
    const glpPerYear = (glp * glpApr) / 100
    const glpPerDay = glpPerYear / 365

    /**
     * GMX
     */
    const gmxPerYear = gmx / 100
    const gmxPerDay = gmxPerYear / 365

    /**
     * Income per week
     */
    const incomePerWeek = (gmxPerDay * gmxPrice + glpPerDay * glpPrice) * 7

    /**
     * Return USD formatted income per week
     */
    return {
        usd: incomePerWeek,
        usdFormatted: new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(incomePerWeek || 0),
    }
}
</script>

<style scoped></style>
