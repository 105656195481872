<script setup>
import { signalTransfer } from '../../../dataProvider.js'
import CoreButton from '../../../components/CoreButton.vue'
import { useWalletStore } from '../../../stores/wallet.js'
import toast from '../../../utils/toast.js'
import { getFutureEscrowAddress } from '../../../dataProvider'
import { useWeb3ModalAccount } from '@web3modal/ethers5/vue'

const { address, chainId, isConnected } = useWeb3ModalAccount()
const wallet = useWalletStore()

async function transfer() {
    const res = await getFutureEscrowAddress(address.value)

    return signalTransfer(res.address)
        .then((res) => {
            return res.wait().then(() => {
                return wallet.updateEscrowAccount()
            })
        })
        .catch((err) => {
            console.error(err)
            const error = err.error
            const message = error.data.message || error.message
            toast.error(message)
        })
        .then((res) => {
            console.log(res)
        })
}
</script>

<template>
    <div class="my-8 transition-opacity duration-300">
        <div class="text-xl font-semibold">Transfer</div>
        <p class="mb-4 text-zinc-400">
            Initiate GMX account transfer to escrow to prepare for listing
        </p>

        <CoreButton class="mt-4 w-full" :handler="transfer">
            Transfer
        </CoreButton>
    </div>
</template>
