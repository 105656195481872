<script setup>
import { Bars3Icon } from '@heroicons/vue/24/outline'
import MobileDropdownMenu from '../components/MobileDropdownMenu.vue'
import MobileLink from './MobileLink.vue'
import { useWalletStore } from '../stores/wallet'

const walletStore = useWalletStore()
</script>

<template>
    <MobileDropdownMenu>
        <template #trigger>
            <div
                class="flex h-9 w-9 items-center justify-center rounded-md hover:bg-zinc-800"
            >
                <Bars3Icon class="h-6 w-6 text-zinc-100"></Bars3Icon>
            </div>
        </template>

        <div>
            <MobileLink
                v-if="walletStore.escrowAddress"
                :to="{
                    name: 'listing.show',
                    params: { address: walletStore.escrowAddress },
                }"
            >
                My account
            </MobileLink>

            <MobileLink
                :to="{
                    name: 'home',
                }"
            >
                View all listings
            </MobileLink>

            <MobileLink
                :to="{
                    name: 'listing.create',
                }"
            >
                Add listing
            </MobileLink>

            <MobileLink
                :to="{
                    name: 'sale-history',
                }"
            >
                Sale history
            </MobileLink>

            <MobileLink
                :to="{
                    name: 'faq',
                }"
            >
                FAQ
            </MobileLink>

            <MobileLink
                :to="{
                    name: 'roadmap',
                }"
            >
                Roadmap
            </MobileLink>
        </div>
    </MobileDropdownMenu>
</template>
