<script setup>
import { approve, createEscrowAccountOffer } from '../../../../dataProvider'
import { ref } from 'vue'
import CoreInput from '../../../../components/CoreInput.vue'
import CoreButton from '../../../../components/CoreButton.vue'
import toast from '../../../../utils/toast'
import { ethers } from 'ethers'
import { useWalletStore } from '../../../../stores/wallet'
import { useWeb3ModalAccount } from '@web3modal/ethers5/vue'

const props = defineProps({
    listingAddress: {
        type: String,
        required: true,
    },
    hasOffer: Boolean,
})

const emit = defineEmits(['offer-created'])

const { address, chainId, isConnected } = useWeb3ModalAccount()
const walletStore = useWalletStore()
const amount = ref(null)
const isLoadingApproval = ref(false)
const isLoadingMakeOffer = ref(false)

async function makeOffer() {
    if (!amount.value) {
        return
    }

    const offerPriceBigNumber = ethers.utils.parseUnits(
        amount.value.toString(),
        'ether'
    )

    const needsApproval = walletStore.allowance.lte(offerPriceBigNumber)

    if (needsApproval) {
        isLoadingApproval.value = true

        await approve(props.listingAddress, offerPriceBigNumber)
            .catch((err) => {
                const error = err.error
                const message = error?.data?.message || error?.message
                toast.error(message)
            })
            .finally(() => {
                isLoadingApproval.value = false
            })
    }

    isLoadingMakeOffer.value = true

    return createEscrowAccountOffer(props.listingAddress, offerPriceBigNumber)
        .then(() => {
            toast.success('Offer created')

            if ('gtag' in window) {
                window.gtag('event', 'offers_create', {
                    event_category: 'offers',
                })
            }

            emit('offer-created', {
                amount: offerPriceBigNumber,
                buyer: address.value,
            })
        })
        .catch((err) => {
            const error = err.error
            const message = error.data.message || error.message
            toast.error(message)
        })
        .finally(() => {
            isLoadingMakeOffer.value = false
        })
}
</script>

<template>
    <div class="flex gap-4">
        <CoreInput
            v-model="amount"
            suffix="GMX"
            max="1000000"
            min="0.1"
            step="0.1"
            type="number"
            placeholder="45.00"
        />

        <CoreButton @click="makeOffer">
            <template v-if="!isLoadingApproval && !isLoadingMakeOffer">
                <template v-if="hasOffer">Update offer</template>
                <template v-else>Make offer</template>
            </template>

            <template v-else>
                <template v-if="isLoadingApproval">Approving...</template>
                <template v-if="isLoadingMakeOffer">Making offer...</template>
            </template>
        </CoreButton>
    </div>
</template>
