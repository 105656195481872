<template>
    <div ref="wrapperEl" class="relative inline-flex">
        <input
            :value="modelValue"
            :class="$style.input"
            class="flex-1 rounded border border-zinc-700 bg-zinc-800 px-4 py-2 text-zinc-200 placeholder-zinc-600 focus:border-gray-300 focus:outline-0 focus:read-only:border-zinc-700"
            :style="{
                paddingRight: suffixWidth ? `${suffixWidth}px` : null,
            }"
            type="text"
            v-bind="$attrs"
            @input="$emit('update:modelValue', $event.target.value)"
        />

        <div
            v-if="suffix"
            ref="suffixEl"
            class="pointer-events-none absolute bottom-1/2 right-2 translate-y-1/2 cursor-default rounded bg-zinc-900 px-2 py-1 text-xs text-zinc-200"
        >
            {{ suffix }}
        </div>
    </div>
</template>

<script setup>
import { nextTick, onMounted, ref, watch } from 'vue'

const props = defineProps({
    modelValue: {
        type: [String, Number],
        required: false,
        default: '',
    },
    suffix: {
        type: String,
        required: false,
        default: '',
    },
})

defineEmits(['update:modelValue'])

const wrapperEl = ref(null)
const suffixEl = ref(null)
const suffixWidth = ref(null)

function getSuffixPadding() {
    if (!props.suffix) return null

    return wrapperEl.value.clientWidth - suffixEl.value.offsetLeft + 10
}

onMounted(() => {
    watch(
        () => props.suffix,
        () => {
            nextTick(() => {
                suffixWidth.value = getSuffixPadding()
            })
        },
        { immediate: true }
    )
})
</script>

<style module>
.input::-webkit-calendar-picker-indicator {
    cursor: pointer;
    border-radius: 4px;
    margin-right: 2px;
    opacity: 0.6;
    filter: invert(0.8);
}

.input::-webkit-calendar-picker-indicator:hover {
    opacity: 1;
}
</style>
