import * as ethers from 'ethers'
import { ARBITRUM, AVALANCHE, getAddress } from './addresses'
import GMXDex from '../abis/GMXDex.json'
import UniswapGMXETH from '../abis/UniswapGMXETH.json'
import BigNumber from 'bignumber.js'
import { getChainId, providers } from './dataProvider'
import { Alchemy, Network } from 'alchemy-sdk'
import GLP from '../abis/GLP.json'
import GMXMarketListingsData from '../abis/GMXListingsData.json'
import GMXMarketListingsDataAvalanche from '../abis/avalanche/GMXListingsData.json'
import EscrowAccount from '../abis/EscrowAccount.json'
import GMXRewardRouter from '../abis/GMXRewardRouter.json'
import QuoterAvalanche from '../abis/avalanche/Quoter.json'
import GMXMarket from '../abis/GMXMarket.json'
import PayinETHorUSDC from '../abis/PayInETHorUSDC.json'
import PayinAVAXorUSDC from '../abis/avalanche/PayinAVAXorUSDC.json'
import bnGMX from '../abis/bnGMX.json'
import { useWalletStore } from './stores/wallet'
import sbfGMXAbi from '../abis/sbfGMX.json'

const settings = {
    apiKey: 'oKWTfm3eZYUcs-jRY7Yzq-eAH0ZIwOw-',
    // apiKey: 'demo',
    // maxRetries: 10,
    network: Network.ARB_MAINNET,
}

const alchemy = new Alchemy(settings)

async function getProvider() {
    let provider = null
    const walletStore = useWalletStore()
    const chainName = walletStore.network

    if ('ethereum' in window) {
        const web3provider = new ethers.providers.Web3Provider(window.ethereum)
        const network = await web3provider.getNetwork()

        if (network.chainId === ARBITRUM) {
            return web3provider
        }
    }

    if (chainName === 'arbitrum') {
        provider = await alchemy.config.getProvider()
    }

    if (chainName === 'avalanche') {
        provider = providers[chainName]
    }

    return provider
}

async function getArbitrumPrices() {
    let provider = await getProvider()
    const chainId = getChainId()

    const GMXDexContract = new ethers.Contract(
        getAddress(chainId, 'GMXDex'),
        GMXDex.abi,
        provider
    )

    const UniswapGMXETHContract = new ethers.Contract(
        getAddress(chainId, 'Uniswap'),
        UniswapGMXETH.abi,
        provider
    )

    const GLPContract = new ethers.Contract(
        getAddress(chainId, 'GLP'),
        GLP.abi,
        provider
    )

    const [tuple, getMinPriceResponse, glpPrice, pricePrecision] =
        await Promise.all([
            UniswapGMXETHContract.slot0(),
            GMXDexContract.getMinPrice(
                '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1'
            ),
            GLPContract.getPrice(true),
            GLPContract.PRICE_PRECISION(),
        ])

    const GLP_USD = glpPrice.toString() / pricePrecision.toString()
    const price = new BigNumber(getMinPriceResponse.toString())
    const sqrtPriceX96 = new BigNumber(tuple[0].toString())

    const ETH_USD = price.div(new BigNumber(10).pow(30))
    const GMX_ETH = sqrtPriceX96
        .multipliedBy(10000)
        .div(new BigNumber(2).pow(96))
        .pow(2)
        .div(100000000)
    const GMX_USD = ETH_USD.div(GMX_ETH)

    return {
        ETH_USD: ETH_USD,
        GMX_ETH: GMX_ETH,
        GMX_USD: GMX_USD,
        GLP_USD: GLP_USD,
    }
}

async function getAvalanchePrices() {
    let provider = await getProvider()
    const chainId = getChainId()

    const GMXDexContract = new ethers.Contract(
        getAddress(chainId, 'GMXDex'),
        GMXDex.abi,
        provider
    )
    const wavaxGetMinPrice = await GMXDexContract.getMinPrice(
        getAddress(chainId, 'WAVAX')
    )

    const GLPContract = new ethers.Contract(
        getAddress(chainId, 'GLP'),
        GLP.abi,
        provider
    )

    const AVAX_USD_IN_CENTS = wavaxGetMinPrice
        .mul(10000)
        .div(ethers.BigNumber.from(10).pow(30))
        .div(100)

    const QuoteContract = new ethers.Contract(
        getAddress(chainId, 'Quoter'),
        QuoterAvalanche.abi,
        provider
    )

    const avax = getAddress(chainId, 'WAVAX')
    const gmx = getAddress(chainId, 'GMX')

    const glpPrice = await GLPContract.getPrice(true)
    const pricePrecision = await GLPContract.PRICE_PRECISION()

    const quoteExactOutputSingle = (val) => {
        return QuoteContract.findBestPathFromAmountOut([avax, gmx], val)
    }

    const quote = await quoteExactOutputSingle(
        ethers.BigNumber.from(10).pow(18)
    )

    const GLP_USD = glpPrice.toString() / pricePrecision.toString()
    const AVAX_USD = AVAX_USD_IN_CENTS.toString() / 100
    const GMX_AVAX = ethers.utils.formatUnits(quote.amounts[0], 18)
    const GMX_USD = GMX_AVAX * AVAX_USD

    return {
        ETH_USD: AVAX_USD.toString(),
        GMX_ETH: GMX_AVAX.toString(),
        GMX_USD: GMX_USD.toString(),
        GLP_USD: GLP_USD.toString(),
    }
}

export const getTokenPrices = async () => {
    const walletStore = useWalletStore()
    const chainName = walletStore.network

    if (chainName === 'arbitrum') {
        return getArbitrumPrices()
    }

    if (chainName === 'avalanche') {
        return getAvalanchePrices()
    }
}
export const escrowAccountIsPurchased = async (address) => {
    const provider = await getProvider()

    const EscrowAccountContract = new ethers.Contract(
        address,
        EscrowAccount.abi,
        provider
    )

    return EscrowAccountContract.IsPurchased()
}
export const escrowAccountIsSold = async (address) => {
    const provider = await getProvider()

    const EscrowAccountContract = new ethers.Contract(
        address,
        EscrowAccount.abi,
        provider
    )

    return EscrowAccountContract.IsSold()
}
export const escrowAccountSaleIneligible = async (address) => {
    const provider = await getProvider()

    const EscrowAccountContract = new ethers.Contract(
        address,
        EscrowAccount.abi,
        provider
    )

    return EscrowAccountContract.SaleIneligible()
}
export const getPendingReceivers = async (buyersEscrowAddress) => {
    const provider = await getProvider()
    const chainId = getChainId()

    const GMXRewardRouterContract = new ethers.Contract(
        getAddress(chainId, 'GMXRewardRouter'),
        GMXRewardRouter.abi,
        provider
    )

    try {
        return GMXRewardRouterContract.pendingReceivers(buyersEscrowAddress)
    } catch (e) {
        throw new Error('Accepting transfer failed')
    }
}
export const checkTransfer = async (escrowAddress) => {
    const provider = await getProvider()
    const chainId = getChainId()

    const GMXRewardRouterContract = new ethers.Contract(
        getAddress(chainId, 'GMXRewardRouter'),
        GMXRewardRouter.abi,
        provider
    )

    return GMXRewardRouterContract.pendingReceivers(escrowAddress)
}
export const getGMXMarketListingDetails = async (address) => {
    const provider = await getProvider()
    const chainId = getChainId()

    let abi = GMXMarketListingsData.abi
    const walletStore = useWalletStore()
    const chainName = walletStore.network

    if (chainName === 'avalanche') {
        abi = GMXMarketListingsDataAvalanche.abi
    }

    const GMXMarketContract = new ethers.Contract(
        getAddress(chainId, 'GMXMarketListing'),
        abi,
        provider
    )

    const detailsResponse = await Promise.all([
        getUnstakedMP(address),
        escrowAccountIsSold(address),
        escrowAccountSaleIneligible(address),
        escrowAccountIsPurchased(address),
        GMXMarketContract.GetGMXListingsData(address),
    ])

    const [unstakedMP, isSold, saleIneligible, isPurchased, listingData] =
        detailsResponse

    const account = {
        address: address,
        GMX_staked: listingData[0],
        esGMX: listingData[1],
        esGMX_staked: listingData[2],
        max_esGMX_vestable_GMX: listingData[3],
        max_esGMX_vestable_GLP: listingData[4],
        tokens_to_vest: listingData[5],
        glp_to_vest: listingData[6],
        GLP: listingData[7],
        pending_ETH: listingData[9],
        pending_esGMX: listingData[10],
        sale_price: listingData[12],
        is_sold: isSold,
        sale_ineligible: saleIneligible,
        is_purchased: isPurchased,
    }

    account.total_gmx = account.GMX_staked.add(account.esGMX)
        .add(account.esGMX_staked)
        .add(account.pending_esGMX)
        .add(account.GMX_staked)

    return account
}

export const getUnstakedMP = async (address) => {
    const provider = await getProvider()
    const chainId = getChainId()

    const GMXMarketContract = new ethers.Contract(
        getAddress(chainId, 'bnGMX'),
        bnGMX.abi,
        provider
    )

    return GMXMarketContract.balanceOf(address)
}

export const getLatestBlock = async () => {
    const provider = await getProvider()
    const blockNumber = await provider.getBlockNumber()

    return provider.getBlock(blockNumber)
}

export const checkTransferApproval = async (
    connectedAddress,
    computedEscrowAddress
) => {
    const provider = await getProvider()
    const chainId = getChainId()

    const feeGmxTrackerContract = new ethers.Contract(
        getAddress(chainId, 'sbfGMX'),
        sbfGMXAbi.abi,
        provider
    )

    const approvalBalance = await feeGmxTrackerContract.stakedAmounts(
        connectedAddress
    )

    const allowance = await feeGmxTrackerContract.allowance(
        connectedAddress,
        computedEscrowAddress
    )

    return {
        approvalBalance,
        allowance,
    }
}

export const getAccountBalances = async (address) => {
    const abi = [
        {
            constant: true,
            inputs: [{ name: '_owner', type: 'address' }],
            name: 'balanceOf',
            outputs: [{ name: 'balance', type: 'uint256' }],
            payable: false,
            type: 'function',
        },
    ]

    const provider = await getProvider()

    const sbfGMXContract = new ethers.Contract(
        getAddress(getChainId(), 'sbfGMX'),
        abi,
        provider
    )
    const vGMXContract = new ethers.Contract(
        getAddress(getChainId(), 'vGMX'),
        abi,
        provider
    )
    const vGLPContract = new ethers.Contract(
        getAddress(getChainId(), 'vGLP'),
        abi,
        provider
    )

    const [sbfGMXBalance, vGMXBalance, vGLPBalance] = await Promise.all([
        sbfGMXContract.balanceOf(address),
        vGMXContract.balanceOf(address),
        vGLPContract.balanceOf(address),
    ])

    return {
        sbfGMX: sbfGMXBalance,
        vGMX: vGMXBalance,
        vGLP: vGLPBalance,
    }
}

export const getAccountDetails = async (address) => {
    const provider = await getProvider()

    const GMXMarketContract = new ethers.Contract(
        getAddress(getChainId(), 'GMXMarketListing'),
        GMXMarketListingsData.abi,
        provider
    )

    /**
     * TODO: Use Promise.all here
     */
    const unstakedMP = await getUnstakedMP(address)

    return GMXMarketContract.GetGMXAccountData(address).then((res) => {
        return {
            GMX_staked: res[0],
            esGMX: res[1],
            esGMX_staked: res[2],
            max_esGMX_vestable_GMX: res[3],
            max_esGMX_vestable_GLP: res[4],
            tokens_to_vest: res[5],
            glp_to_vest: res[6],
            GLP: res[7],
            pending_ETH: res[9],
            pending_esGMX: res[10],
        }
    })
}

export const getEscrowAccountOffers = async (escrowAddress) => {
    const provider = await getProvider()

    const EscrowAccountContract = new ethers.Contract(
        escrowAddress,
        EscrowAccount.abi,
        provider
    )

    const offersCount = await EscrowAccountContract.GetNumberOfOffers()

    if (offersCount.toNumber() === 0) {
        return Promise.resolve([])
    }

    const offers = await EscrowAccountContract.GetOffers(offersCount, 0)

    return Promise.all(
        offers.map((address) => {
            return EscrowAccountContract.offers(address)
        })
    )
}

export const generateSalt = async (walletAddress) => {
    const chainId = getChainId()
    const provider = await getProvider()

    const GMXMarketContract = new ethers.Contract(
        getAddress(chainId, 'GMXMarket'),
        GMXMarket.abi,
        provider
    )

    const escrowCounter = await GMXMarketContract.EscrowCounter(walletAddress)
    const shortWalletAddress = walletAddress.slice(0, 10)

    return ethers.utils.formatBytes32String(
        `${shortWalletAddress}${escrowCounter.toString()}`
    )
}

export const calculateFee = async (address, price) => {
    const chainId = getChainId()
    const provider = await getProvider()

    let addressKey = ''
    let abi = null

    if (chainId === AVALANCHE) {
        addressKey = 'PayinAVAXorUSDC'
        abi = PayinAVAXorUSDC.abi
    }

    if (chainId === ARBITRUM) {
        addressKey = 'PayinETHorUSDC'
        abi = PayinETHorUSDC.abi
    }

    const contract = new ethers.Contract(
        getAddress(chainId, addressKey),
        abi,
        provider
    )

    // Returns { FeeBP, Fees, Payout }
    return contract.FeeCalc(address, price)
}
