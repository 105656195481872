<template>
    <Bar :data="chartData" :options="chartOptions" />
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    LineController,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Line, Bar } from 'vue-chartjs'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
dayjs.extend(isBetween)

import sortBy from 'lodash/sortBy.js'
import { formatUsd } from '../../utils/numbers.js'

const props = defineProps({
    listings: {
        type: Array,
        required: true,
    },
    startDate: {
        type: String,
        required: true,
    },
    endDate: {
        type: String,
        required: true,
    },
})

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    LineController,
    BarElement,
    Title,
    Tooltip,
    Legend
)

// Get weeks between two dates, including the last
function getWeeks(startDate, endDate) {
    const weeks = []
    let currentDate = dayjs(startDate).startOf('day')
    const end = dayjs(endDate).endOf('day')

    while (currentDate.isBefore(end)) {
        const startOfWeek = currentDate.startOf('week')
        const endOfWeek = currentDate.endOf('week')
        weeks.push({
            start: startOfWeek,
            end: endOfWeek,
        })
        currentDate = currentDate.add(1, 'week')
    }

    return weeks
}

const sortedListings = computed(() => {
    return sortBy(props.listings, (listing) => {
        return listing.endat
    })
})

const groupedListings = computed(() => {
    const weeks = getWeeks(props.startDate, props.endDate)
    const groupedData = {}

    weeks.forEach((week) => {
        const weekStart = week.start.format('YYYY-MM-DD')
        const weekEnd = week.end.format('YYYY-MM-DD')
        groupedData[`${weekStart} - ${weekEnd}`] = sortedListings.value.filter(
            (listing) => {
                const listingDate = dayjs(listing.endat * 1000)
                return listingDate.isBetween(week.start, week.end)
            }
        )
    })
    return groupedData
})

const totalSalesInUsd = computed(() => {
    const salesData = []
    Object.entries(groupedListings.value).forEach(([week, listings]) => {
        const sum = listings.reduce(
            (acc, listing) =>
                acc + Number(listing.saleprice) * Number(listing.gmxusd),
            0
        )

        const month = dayjs(week.split(' - ')[1]).format('MMMM')

        salesData.push({ label: week, value: sum, month })
    })
    return salesData
})

let cumulativeSalesInUsd = computed(() => {
    let sum = 0
    const cumulativeData = []
    totalSalesInUsd.value.forEach(({ label, value }) => {
        const week = label
        sum += value

        const month = dayjs(week.split(' - ')[1]).format('MMMM')

        cumulativeData.push({ value: sum, label: week, month })
    })
    return cumulativeData
})

const chartData = ref({
    type: 'bar',
    element: [LineElement, Line],
    datasets: [
        {
            label: 'Cumulative Sales in USD',
            borderColor: '#ff6384',
            backgroundColor: '#ff6384',
            data: cumulativeSalesInUsd.value,
            parsing: {
                yAxisKey: 'value',
                xAxisKey: 'label',
            },
            type: 'line',
            yAxisID: 'y1', // associate with the right y-axis
        },
        {
            label: 'Sales in USD',
            borderColor: '#607ac0',
            backgroundColor: '#103cc4',
            data: totalSalesInUsd.value,
            parsing: {
                yAxisKey: 'value',
                xAxisKey: 'label',
            },
            yAxisID: 'y',
        },
    ],
})

const chartOptions = ref({
    responsive: true,
    interaction: {
        intersect: false,
        mode: 'index',
    },
    scales: {
        // x: {
        //     ticks: {
        //         callback: (value, index, ticks) => {
        //             const val = cumulativeSalesInUsd.value[index].month
        //
        //             console.log(val)
        //
        //             return val
        //         },
        //     },
        // },
        y: {
            position: 'left',
            ticks: {
                callback: (value, index, ticks) => {
                    return formatUsd(value, null, 0)
                },
            },
        },
        y1: {
            position: 'right',
            ticks: {
                callback: (value, index, ticks) => {
                    return formatUsd(value, null, 0)
                },
            },
            grid: {
                drawOnChartArea: false, // only want the grid lines for one axis to show up
            },
        },
    },
})
</script>
