<script setup>
import { ModalsContainer } from 'vue-final-modal'
import MainFooter from './MainFooter.vue'
import MainNavigation from './layout/MainNavigation.vue'
import { useWalletStore } from './stores/wallet.js'
import { useSanityClient } from 'vue-sanity'
import { watch } from 'vue'
import { useWeb3ModalAccount } from '@web3modal/ethers5/vue'
import AppBanner from './AppBanner.vue'

const { address } = useWeb3ModalAccount()
const walletStore = useWalletStore()

walletStore.pricePromise()

watch(
    address,
    () => {
        walletStore.updateEscrowAccount()
    },
    { immediate: true }
)

useSanityClient({
    projectId: 'ufj1p5yj',
    apiVersion: '2023-02-27',
    dataset: import.meta.env.PROD ? 'production' : 'stage',
    useCdn: import.meta.env.PROD,
})
</script>

<template>
    <div class="h-full">
        <AppBanner />

        <MainNavigation />

        <div class="mt-10 px-2 pb-12 md:px-4">
            <router-view :key="$route.fullPath"></router-view>
        </div>

        <MainFooter />
    </div>

    <modals-container></modals-container>
</template>
