import { defineStore } from 'pinia'
import axios from 'axios'

export const useListingsHistoryStore = defineStore('listingsHistory', {
    state: () => {
        return {
            listings: [],
            totalSalesInUsd: null,

            page: 1,
            limit: 200,
            total: null,
            params: {},
            sortBy: '',
            sortOrder: 'asc',

            isLoading: false,
        }
    },

    getters: {
        totalEsGmxSold() {
            return this.listings.reduce((acc, listing) => {
                return acc + Number(listing.totalesgmx)
            }, 0)
        },

        totalVolumeInUSD() {
            return this.listings.reduce((acc, listing) => {
                return acc + Number(listing.saleprice) * Number(listing.gmxusd)
            }, 0)
        },
    },

    actions: {
        async fetchListingsHistory(chainName, options = {}) {
            let urlv1 = null
            let urlV2 = null

            if (chainName === 'arbitrum') {
                urlv1 = 'https://api.sntl.market/sold'
                urlV2 = 'https://api.sntl.market/soldv2'
            }

            if (chainName === 'avalanche') {
                urlv1 = 'https://avaxapi.sntl.market/sold'
                urlV2 = 'https://avaxapi.sntl.market/soldv3'
            }

            let params = {
                limit: 1000,
                offset: (this.page - 1) * this.limit,
            }

            if (options.sortBy) {
                params.order = options.sortBy
            }

            if (options.to) {
                const toDate = new Date(options.to)
                params.endat = 'lt.' + toDate.getTime() / 1000
            }

            if (options.from) {
                const fromDate = new Date(options.from)
                params.endat = 'gt.' + fromDate.getTime() / 1000
            }

            const responsev2Promise = axios.get(urlV2, {
                params,
                headers: {
                    Prefer: 'count=exact',
                },
            })

            const responsev1Promise = axios.get(urlv1, {
                params,
                headers: {
                    Prefer: 'count=exact',
                },
            })

            // if (chainName === 'avalanche') {
            //     apiUrl = 'https://avaxapi.sntl.market/soldv2'
            // }

            // const queryString = Object.keys(params)
            //     .map((key) => key + '=' + params[key])
            //     .join('&')

            const [responsev2, responsev1] = await Promise.all([
                responsev2Promise,
                responsev1Promise,
            ])

            this.listings = [...responsev2.data, ...responsev1.data]
            this.total =
                Number(responsev2.headers['content-range'].split('/')[1]) +
                Number(responsev1.headers['content-range'].split('/')[1])
        },
        // async nextPage() {
        //     this.page++
        //
        //     this.isLoading = true
        //     const res = await this.fetchListingsHistory(this.page, this.limit)
        //     this.accounts.push(...res.data)
        //     this.isLoading = false
        //
        //     return res
        // },
    },
})
