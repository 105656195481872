<script setup>
import { computed } from 'vue'
import { defaultAccountListing, useWalletStore } from '../../../stores/wallet'
import { useManageAccountModal } from '../../../composers/modals.js'
import ListingBuyButton from './ListingBuyButton.vue'
import ListingManageButton from './ListingManageButton.vue'

const props = defineProps({
    listing: {
        type: Object,
        required: true,
        default: () => defaultAccountListing,
    },
    address: {
        type: String,
        required: true,
    },
})

const walletStore = useWalletStore()
const accountAddressForSale = props.address

const isYourListing = computed(() => {
    return walletStore.escrowAddress === props.address
})
</script>

<template>
    <div
        class="flex flex-col gap-4 border-b border-zinc-700 p-5 md:flex-row md:items-center md:justify-between md:gap-12"
    >
        <div>
            <h1 class="relative flex items-center text-xl font-bold text-white">
                <template v-if="isYourListing">Your account</template>
                <template v-else>Account details</template>

                <span
                    v-if="listing?.is_sold"
                    class="ml-3 rounded-md bg-red-500 p-1 py-0.5 text-xs font-semibold"
                >
                    SOLD
                </span>
            </h1>
            <a
                :href="`${walletStore.explorerUrl}/address/${accountAddressForSale}`"
                target="_blank"
                rel="noopener noreferrer"
                class="text-sm text-zinc-400 transition hover:text-zinc-300"
            >
                {{ accountAddressForSale }}
            </a>

            <p
                v-if="isYourListing"
                class="mt-2 italic text-white underline"
                :title="walletStore.getEscrowAccountState"
            >
                {{ walletStore.getEscrowAccountStateText }}
            </p>
        </div>

        <ListingBuyButton v-if="listing.address" :listing="listing" />

        <ListingManageButton :address="accountAddressForSale" />
    </div>
</template>
