<template>
    <div class="@container">
        <div class="flex flex-col gap-8 @2xl:flex-row @2xl:gap-4">
            <div class="flex flex-col gap-4">
                <div
                    v-for="(row, key) in values"
                    :key="key"
                    class="flex items-center gap-4"
                >
                    <CoreInput
                        id="mp"
                        v-model="row.value"
                        class="w-full md:w-48"
                        :suffix="key"
                        type="text"
                        autocomplete="off"
                    />
                    <span>*</span>
                    <CoreInput
                        v-bind="row.multiplierAttrs"
                        v-model="row.multiplier"
                        type="text"
                        class="w-full md:w-40"
                        step="0.001"
                        autocomplete="off"
                    />
                </div>
            </div>

            <div
                class="flex flex-1 items-center justify-center text-center text-base"
            >
                <div>
                    <strong>Estimated price</strong>
                    <br />
                    {{ formatEther(totalPrice) }} GMX ({{
                        formatUsd(totalPrice, walletStore.price.GMX_USD)
                    }})
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import CoreInput from './CoreInput.vue'
import { formatEther, formatUsd } from '../utils/numbers'
import { useWalletStore } from '../stores/wallet'
import { ethers } from 'ethers'

const walletStore = useWalletStore()
const props = defineProps({
    listing: {
        type: Object,
        required: true,
        default: () => {},
    },
})

function getValue(val) {
    if (!val || val?.isZero()) {
        return 0
    }

    return Number(ethers.utils.formatEther(val)).toFixed(2) || 0
}

const totalPrice = ref(0)
const values = ref({
    esGMX: {
        value: 20,
        multiplier: 0.4,
        multiplierAttrs: {
            suffix: 'GMX',
        },
    },
    GMX: {
        value: 0,
        multiplier: 1,
        multiplierAttrs: {
            suffix: 'GMX',
            readonly: true,
        },
    },
    GLP: {
        value: 0,
        multiplier: 1,
        multiplierAttrs: {
            readonly: true,
            suffix: 'GLP',
        },
    },
})

watch(values, () => {
    if ('gtag' in window) {
        window.gtag('event', 'price_estimator_update', {
            event_category: 'price_estimator',
            event_label: props.listing.address,
        })
    }
})

const calculatePrice = async () => {
    const prices = await walletStore.pricePromise()

    totalPrice.value = 0
    // Calculate the price based on the provided rules and multipliers
    totalPrice.value = values.value.GMX.value * values.value.GMX.multiplier
    totalPrice.value += values.value.esGMX.value * values.value.esGMX.multiplier

    totalPrice.value +=
        values.value.GLP.value * (prices.GLP_USD / prices.GMX_USD)
}

watch(
    () => props.listing,
    (val) => {
        if (!val) {
            return
        }

        values.value.esGMX.value = getValue(val.esGMX_staked.add(val.esGMX))
        values.value.GMX.value = getValue(val.GMX_staked)
        values.value.GLP.value = getValue(val.GLP)

        calculatePrice()
    },
    { immediate: true }
)

// Watch for changes in inputs and recalculate price
watch(values, calculatePrice, { deep: true })
</script>
