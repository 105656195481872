<script setup>
import CoreButton from '../../../components/CoreButton.vue'
import { useWalletStore } from '../../../stores/wallet'
import { formatEther } from '../../../utils/numbers'
import { useBuyAccountModal } from '../../../composers/modals'

const props = defineProps({
    listing: {
        type: Object,
        required: true,
    },
})

const walletStore = useWalletStore()
const { buyAccountModal } = useBuyAccountModal(props.listing)

function openBuyModal() {
    buyAccountModal.open()
}
</script>

<template>
    <CoreButton
        v-if="
            listing.address !== walletStore.escrowAddress &&
            !listing.is_sold &&
            !listing.is_purchased
        "
        size="sm"
        class="mt-4 disabled:opacity-50 md:mt-0"
        @click="openBuyModal(listing)"
    >
        Buy for {{ formatEther(listing.sale_price) }} GMX
    </CoreButton>
</template>
